import React from 'react';

// Components
import { ProgrammeHeader } from 'components';

// Router
import { RenderRoutes, programmeRoutes } from 'router';
import { PageAnimationContainer } from 'styles/animations';

const TimeTable: React.FC = () => {
  return (
    <PageAnimationContainer key="programme-page">
      <ProgrammeHeader />
      <RenderRoutes routes={programmeRoutes} />
    </PageAnimationContainer>
  );
};

export default TimeTable;
