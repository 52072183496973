import React, { ReactNode } from 'react';

// Theme
import CSSreset from '../../styles/CSSreset';

// Providers
import { DefaultTheme, ThemeProvider } from 'styled-components';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';

interface IThemeContext extends IWithAppState {
  children: ReactNode;
  theme: DefaultTheme;
}

/**
 * Context component to hold all generic styles of the application.
 *
 * @author Matt Hamers
 */
const ThemeContextProvider: React.FC<IThemeContext> = ({ children, theme, state }) => {
  const themeWithPageColors: DefaultTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      foreground: state?.colors?.foreground,
      background: state?.colors?.background,
      modalForeground: state?.streamModal.colors.foreground,
      modalBackground: state?.streamModal.colors.background,
    },
  };

  return (
    <ThemeProvider theme={themeWithPageColors}>
      <CSSreset />
      {children}
    </ThemeProvider>
  );
};

export default withAppState(ThemeContextProvider);
