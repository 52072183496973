import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';

// Local styles
import { StyledImage, ImagesContainer } from './shuffle-image.styles';
interface IShuffleImageProps {
  images?: string[];
  onAnimate?: () => void;
}

const ShuffleImage: React.FC<IShuffleImageProps> = ({ images, onAnimate }) => {
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [imagesLoadedState, setImagesLoadedState] = useState({
    0: 'notLoaded',
    1: 'notLoaded',
    2: 'notLoaded',
    3: 'notLoaded',
    4: 'notLoaded',
    5: 'notLoaded',
    6: 'notLoaded',
    7: 'notLoaded',
    8: 'notLoaded',
    9: 'notLoaded',
  });

  const updateLoadedState = (i: number) => {
    setImagesLoadedState((prevState) => ({
      ...prevState,
      [i]: 'loaded',
    }));
  };

  useEffect(() => {
    if (!Object.keys(imagesLoadedState).some((key: any) => imagesLoadedState[key as keyof typeof imagesLoadedState] === 'notLoaded')) {
      onAnimate && onAnimate();
      setAllImagesLoaded(true);
    }
  }, [imagesLoadedState]);

  return (
    <AnimatePresence>
      <ImagesContainer>
        {images?.map((image: string, i: number) => (
          <StyledImage key={i} src={image} onLoad={() => updateLoadedState(i)} num={i} hasLoaded={allImagesLoaded} />
        ))}
      </ImagesContainer>
    </AnimatePresence>
  );
};

export default ShuffleImage;
