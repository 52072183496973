import React from 'react';

// Components
import { Flex, Icon } from 'components';

// Local Styles
import { NavButton } from './slider-nav-buttons.styles';

interface ISliderNavButtonsProps {
  color?: string;
  onNextClick: () => void;
  onPrevClick: () => void;
  isNextInActive?: boolean;
  isPrevInActive?: boolean;
}

const SliderNavButtons: React.FC<ISliderNavButtonsProps> = ({ color, onNextClick, isNextInActive, onPrevClick, isPrevInActive }) => (
  <Flex>
    <NavButton isInActive={isPrevInActive}>
      <Icon icon="arrowLeft" onClick={onPrevClick} color={color} mr="16px" fontSize={{ xs: '20px', md: '28px' }} />
    </NavButton>
    <NavButton isInActive={isNextInActive}>
      <Icon icon="arrowRight" onClick={onNextClick} color={color} fontSize={{ xs: '20px', md: '28px' }} />
    </NavButton>
  </Flex>
);

export default SliderNavButtons;
