import React from 'react';
import styled from 'styled-components';
import { variant, color, space, typography, textStyle } from 'styled-system';
import { system } from 'lib';

// Interfaces
import { System } from 'lib';

export interface TextProps extends System {
  variant?: string;
}

export const TextComponent: React.FC<TextProps> = styled.span<TextProps>(
  () =>
    variant({
      variants: {
        smallBold: {
          fontSize: '14px',
          lineHeight: 1,
          fontWeight: 'bold',
        },
        variant1: {
          display: 'block',
          fontStyle: 'normal',
          fontSize: '8.5rem',
          lineHeight: 1,
          fontWeight: 900,
        },
      },
    }),
  textStyle,
  color,
  typography,
  space,
  system
);
