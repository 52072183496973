import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styles
import { breakpoint } from 'styles/breakpoint';

export const SliderWrapper = styled(motion.div)`
  & * > .slide-container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all ease;
  }

  & * > .slide-container:first-child {
    padding-left: 20px;

    ${breakpoint.md`
    padding-left: 70px;
    `}
  }
  ${breakpoint.md`
    margin-bottom: 100px;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  ${breakpoint.md`
    padding: 0 70px;
  `}
`;
