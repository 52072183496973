import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoint } from 'styles/breakpoint';

export const ModalContent = styled.div`
  width: 100%;
  display: block;
  max-width: 1460px;
  padding: 40px 16px 0 16px;

  ${breakpoint.sm`
    padding: 64px 32px 0 32px;
  `}

  ${breakpoint.md`
    padding: 126px 90px 0 90px;
  `}
`;

export const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;
export const ModalContainer = styled(motion.div)`
  height: calc(100% - 78px);
  width: 100%;
  display: flex;
  position: absolute;
  border-radius: 16px 16px 0 0;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => `
      background-color: ${theme.colors.modalBackground};
  `};

  ${breakpoint.md`
    height: calc(100% - 58px);
    border-radius: 0;
  `}

  &:before {
    background-color: #000000;
    opacity: 0.1;
    position: absolute;
    content: '';
    width: 116px;
    height: 4px;
    border-radius: 4px;
    top: 14px;

    ${breakpoint.md`
      display: none;
    `}
  }
`;
export const ButtonWrapper = styled(motion.div)`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;

  ${breakpoint.md`
    left: auto;
    right: 10px;
    top: 110px;
  `}
`;
