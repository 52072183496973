import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

// Assets
import logo from 'assets/logo-short.svg';

// Components
import { Flex, Box } from 'components';

// Styled components
import { LocationPinContainer, PinWrapper, Logo, Title, LiveBadge, Pulse, HoverPulse } from './LocationPin.styles';

// Hooks
import { useIsBreakpoint } from 'hooks';

export const LocationPin: React.FC<any> = (props) => {
  const { isMobile } = useIsBreakpoint();
  const [hover, setHover] = useState(false);

  return (
    <LocationPinContainer {...props}>
      <PinWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Flex position="relative" width="30px" height="30px">
          <AnimatePresence initial>
            <Pulse key="1" initial animate={{ scale: 2.2, opacity: 0 }} transition={{ duration: 1.5, repeat: Infinity }} />
            <HoverPulse key="2" animate={hover ? { scale: 1.9, opacity: 1 } : { scale: 1, opacity: 0 }} />
            <Logo src={logo} alt="stage-logo" />
          </AnimatePresence>
        </Flex>
        <Flex ml={['0px', '0px', '6px', '6px']} flexDirection="column" mt={props.isLive && !isMobile ? '-25px' : '-25px'}>
          {props.isLive ? <LiveBadge>LIVE</LiveBadge> : <Box height="22px" />}
          <Title>{props.name}</Title>
        </Flex>
      </PinWrapper>
    </LocationPinContainer>
  );
};
