import React from 'react';

// Components
import { Text, Icon } from 'components';

// Local styles
import { Container, InnerContainer, BoxLeft, BoxRight, BoxBottom, LinkList, LinkItem, Link, LogoImg, LogoLink } from './footer.styles';

// Assets
import npoLogo from 'assets/npo.svg';
import threeFmLogo from 'assets/3fm-logo.png';
import threeFor12Logo from 'assets/3voor12-logo.png';

interface IFooterProps {
  color?: string;
  hoverColor?: string;
}

const Footer: React.FC<IFooterProps> = ({ color, hoverColor }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <InnerContainer>
        <BoxLeft>
          <Icon icon="logoIcon" color={color || 'white'} mr="10px" fontSize="30px" />
          <Text fontSize={{ xs: 0, md: '1.5rem' }} color={color || 'white'} fontWeight="bold">
            © ESNS {currentYear}
          </Text>
        </BoxLeft>
        <BoxRight>
          <LinkList>
            <LinkItem>
              <Link
                href="https://esns.nl/info/about-esns/"
                color={color || 'white'}
                hoverColor={hoverColor || 'foreground'}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Text fontSize={{ xs: 0, md: '1.5rem' }} fontWeight="bold">
                  About esns
                </Text>
              </Link>
            </LinkItem>
            <LinkItem>
              <Link
                href="https://esns.nl/privacy-policy/"
                color={color || 'white'}
                hoverColor={hoverColor ? hoverColor : 'foreground'}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Text fontSize={{ xs: 0, md: '1.5rem' }} fontWeight="bold">
                  Privacy
                </Text>
              </Link>
            </LinkItem>
          </LinkList>
        </BoxRight>

        <BoxBottom>
          <LogoLink href="https://3voor12.vpro.nl/" target="_blank" rel="noreferrer noopener">
            <LogoImg src={threeFor12Logo} alt="3 voor 12 vpro" />
          </LogoLink>
          <LogoLink href="https://over.npo.nl/" target="_blank" rel="noreferrer noopener">
            <LogoImg src={npoLogo} alt="npo" />
          </LogoLink>
          <Icon icon="euro-radio" m={{ xs: '0 10px', md: '0 14px' }} fontSize={{ xs: '70px', md: '100px' }} />
          <LogoLink href="https://www.npo3fm.nl/" target="_blank" rel="noreferrer noopener">
            <LogoImg src={threeFmLogo} alt="npo 3 fm" />
          </LogoLink>
        </BoxBottom>
      </InnerContainer>
    </Container>
  );
};

export default Footer;
