import React, { useRef, useState } from 'react';
import Swiper from 'react-id-swiper';

// Styles
import './swiper.css';

// Components
import { Flex, Text, SliderNavButtons, Box } from 'components';

// Styled components
import { SliderWrapper, TitleContainer } from './location-slider.styles';

// Interfaces
import { ITimetableToday } from 'types/interfaces';
import { Slide } from './Slide';

interface ILocationSlider {
  title: string;
  timeTable: ITimetableToday;
}

export const LocationSlider: React.FC<ILocationSlider> = ({ title, timeTable }) => {
  const [swiper, setSwiper] = useState({
    activeIndex: undefined,
    slidesLength: undefined,
    isEnd: undefined,
  });
  const swiperRef = useRef(null);

  const swiperParams = {
    slidesPerView: 'auto' as number | 'auto' | undefined,
    spaceBetween: 0,
    freeMode: true,
    grabCursor: true,
    on: {
      init: () => {
        swiperRef && updateSwiperState((swiperRef as any).current.swiper);
      },
      slideChange: () => {
        swiperRef && updateSwiperState((swiperRef as any).current.swiper);
      },
    },
  };

  const navigate = (dir?: 'next ' | 'prev') => {
    if (swiperRef.current && (swiperRef as any)?.current.swiper) {
      if (dir === 'prev') {
        (swiperRef as any)?.current.swiper.slidePrev();
      } else {
        (swiperRef as any)?.current.swiper.slideNext();
      }

      swiperRef && updateSwiperState((swiperRef as any).current.swiper);
    }
  };

  const updateSwiperState = (swiper: any) => {
    setSwiper({
      activeIndex: swiper.activeIndex,
      isEnd: swiper.isEnd,
      slidesLength: swiper.slides.length,
    });
  };

  return (
    <Flex flexDirection="column" width="100%" mb={{ xs: '40px', sm: 0 }}>
      <TitleContainer>
        <Text color="yellow" fontSize={{ xs: 1, sm: 5 }} fontWeight="bold" mb={{ xs: '10px', sm: '20px' }}>
          {title.toUpperCase()}
        </Text>
        <Box mb={{ xs: '8px', sm: 0 }} pt={{ xs: 0, sm: '14px' }}>
          <SliderNavButtons
            color="white"
            onNextClick={() => navigate()}
            onPrevClick={() => navigate('prev')}
            isNextInActive={swiper.isEnd}
            isPrevInActive={swiper.activeIndex === 0}
          />
        </Box>
      </TitleContainer>
      <Box>
        <SliderWrapper>
          <Swiper ref={swiperRef} {...swiperParams}>
            {timeTable.stages &&
              timeTable.stages[Object.keys(timeTable.stages)[0]].map((item, i) => (
                <div key={i} className="slide-container">
                  <Slide {...item} />
                </div>
              ))}
          </Swiper>
        </SliderWrapper>
      </Box>
    </Flex>
  );
};

export default LocationSlider;
