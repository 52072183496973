import styled from 'styled-components';
import { breakpoint } from 'styles';
import { LayoutContainer } from 'styles/containers';

export const TitleContainer = styled.div`
  width: 100vw;
  left: calc(50% - 50vw);
  overflow: hidden;

  ${breakpoint.md`
  `}
`;

export const ContentContainer = styled(LayoutContainer)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 100px;
  padding-bottom: 50px;

  ${breakpoint.md`
    flex-direction: row;
    margin-top: 200px;
    padding-bottom: 200px;
  `}
`;
