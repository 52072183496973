import styled, { css, keyframes } from 'styled-components';

// Components
import { Button, Icon } from 'components';

// Styles
import aspectRatio from 'styles/aspectRatio';
import { breakpoint } from 'styles/breakpoint';

export const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 100px;

  ${({ theme }) => `
      background-color: ${theme.colors.modalForeground};
  `};
`;

export const HeartButton = styled(Button)`
  ${breakpoint.md`
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export const HeartZoomContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: -60px;

  ${breakpoint.md`
    bottom: 50px;
    justify-content: flex-end;
    padding: 0 40px;
    height: 80px;
    align-items: flex-end;
  `}
`;

export const TitleContainer = styled.div`
  top: 100px;
  width: 100vw;

  ${aspectRatio(16, 9)}
  position: absolute;

  ${breakpoint.md`
    top: -50px;
  `}

  ${breakpoint.lg`
    top: -120px;
  `}

  ${breakpoint.xl`
    top: -140px;
    max-width: 1440px;
  `}

  > * {
    position: relative;
    left: -24px;

    ${breakpoint.lg`
    left: -88px;
  `}

    ${breakpoint.xl`
      left: -136px;
    `}
  }
`;

export const DesktopText = styled.span`
  display: none;
  white-space: pre;

  ${breakpoint.md`
    display: inline;
  `}
`;

export const ArtistButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: -76px;
  position: relative;
  z-index: 2;
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  ${breakpoint.md`
    justify-content: center;
    top: -88px;
  `}
`;

export const HeartsAnimation = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const heartPulse = keyframes`
  0% {
		opacity: 0;
    transform: scale(0.1);
	}

  50% {
    opacity: 1;
		transform: scale(2.5);
	}

	100% {
    opacity: 0;
		transform: scale(0.6);
	}
`;

const easing = `cubic-bezier(.17, .89, .32, 1.49)`;
const duration = 1.4;

const commonHeartStyles = css`
  will-change: transform;
  opacity: 0;
  position: absolute;
`;

export const Heart1 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration}s 0.1s ${easing};
  top: -32px;
  left: 20%;
`;

export const Heart2 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration}s 0.3s ${easing};
  top: -10px;
  right: 3%;
`;

export const Heart3 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration * 1.2}s 0.4s ${easing};
  top: 30%;
  left: 50%;
`;

export const Heart4 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration}s 0.3s ${easing};
  top: 50%;
  left: 0;
`;

export const Heart5 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration}s 0.6s ${easing};
  top: 70%;
  right: -20px;
`;

export const Heart6 = styled(Icon)`
  ${commonHeartStyles};
  animation: ${heartPulse} ${duration}s 0.25s ${easing};
  bottom: -24px;
  right: 70%;
`;
