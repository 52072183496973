import React from 'react';

import { Text } from 'components';

import { Container, QuoteText, CaptionText } from './quote.styles';

interface IQuoteProps {
  color?: string;
  quoteText?: string;
  captionText?: string;
}

const Quote: React.FC<IQuoteProps> = ({ color = 'white', quoteText, captionText }) => (
  <Container>
    <QuoteText>
      <Text color={color} fontWeight="heavy" fontSize={{ xs: '2.4rem', sm: 5, lg: 8 }} lineHeight="98%" textAlign="center">
        {`“`}
        {quoteText}
        {`”`}
      </Text>
    </QuoteText>

    <CaptionText>
      <Text
        color={color}
        fontWeight="heavy"
        fontSize={{ xs: 0, md: 1 }}
        mt={{ xs: '16px', md: '32px' }}
        lineHeight="98%"
        textAlign="center"
      >
        {captionText}
      </Text>
    </CaptionText>
  </Container>
);

export default Quote;
