import styled from 'styled-components';

// Styles
import { breakpoint } from 'styles';

export const Container = styled.div`
  pointer-events: none;
  position: relative;
  text-align: left;
  left: -20px;

  ${breakpoint.sm`
     left: -40px;
  `}

  ${breakpoint.md`
     left: -80px;
  `}

  ${breakpoint.xl`
     left: -100px;
  `}
`;
