import {
  BackgroundProps,
  BorderProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  compose,
  layout,
  color,
  space,
  background,
  border,
  grid,
  position,
  shadow,
  typography,
  flexbox,
  OverflowProps,
  BackgroundColorProps,
  OpacityProps,
} from 'styled-system';
import { css } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const system = (p: any): any => css`
  &&&& {
    ${compose(layout, color, space, background, border, grid, position, shadow, typography, flexbox)(p)}
  }
`;

export interface ColorProps extends BackgroundColorProps, OpacityProps {
  color?: any;
}

export interface System
  extends BackgroundProps,
    BorderProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    ColorProps,
    OverflowProps,
    TypographyProps {
  // SVG color properties
  fill?: ColorProps['color'];
  stroke?: ColorProps['color'];

  // css
  css?: any;
}

export type ISystem<T = undefined> = T extends {} ? System & Omit<Omit<T, 'color'>, 'css'> : System;
