export const loadExternalScripts = () => {
  (function () {
    const ccmWrapperElement = document.createElement('script');
    const organisation = 'NPO';
    const site = 'npo3fm.nl';
    const ccmWrapperVersion = 'v1.1';
    const ccmDomain = 'https://ccm.npo.nl';
    ccmWrapperElement.type = 'text/javascript';
    ccmWrapperElement.crossOrigin = 'anonymous';
    ccmWrapperElement.src = ccmDomain + '/sites/' + organisation + '/' + site + '/ccm-wrapper_' + ccmWrapperVersion + '.js';
    ccmWrapperElement.async = true;
    ccmWrapperElement.defer = ccmWrapperElement.async;
    const s = document.getElementsByTagName('script')[0];
    (s as any).parentNode.insertBefore(ccmWrapperElement, s);
    window.addEventListener(
      'CCM_Wrapper_ready',
      function () {
        (window as any).ccmWrapper.init();
      },
      false
    );
  })();
};
