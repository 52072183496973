const baseUrl = 'http://localhost:8080/api/v1/';

const apiEndpoints = {
  getTimetable: `/static/timetable.json`,
  getTimetableAZ: `/static/timetable-az.json`,
  getTimetableToday: `/static/timetable-today.json`,
  getTimeTableStage: `/static//timetable/`,
  getArtist: `/static/artist/`,
  getStage: `/static/stage/`,
  getAllStages: `/static/stage-all.json`,
  getAllArtists: `/static/all-artists.json`,
  getExploreFeed: `/static/explore-feed.json`,
  getNewsArticle: `${baseUrl}news-article/`,
  getSettings: `/static/settings.json`,
};
console.log(apiEndpoints.getTimetable);
export { apiEndpoints };
