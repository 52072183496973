import styled from 'styled-components';
import { motion } from 'framer-motion';

// Breakpoints
import { breakpoint } from 'styles/breakpoint';

export const SliderWrapper = styled(motion.div)`
  position: absolute;
  bottom: 90px;
  width: 100vw;
  z-index: 10;

  ${breakpoint.md`
    bottom: 0;
    left: 0;
    padding: 20px 0 20px 0;
    width: calc(120vw + 30px);
    padding-right: calc(20vw + 30px);
  `}

  & * > .slide-container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all ease;
  }
  & * > .slide-container:last-child {
    margin-right: 200px;
  }
`;
