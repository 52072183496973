import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

// Styles
import { breakpoint } from 'styles';

// Components
import { Box, Img } from 'components';

const imageSizes = css`
  width: 265px;
  height: 265px;

  ${breakpoint.md`
      width: 400px;
      height: 400px;
    `}
`;

export const ShuffleContainer = styled(Box)`
  position: fixed;
  width: 100%;
  top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  transform: translateZ(0);

  ${breakpoint.md`
    top: 160px;
  `}
`;

export const TitleContainer = styled.div`
  position: fixed;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  transform: translateZ(0);
  top: 420px;
  width: 100vw;
  left: calc(50% - 50vw);

  display: flex;
  flex-direction: column;

  ${breakpoint.md`
    top: 280px;
  `}
`;

export const ShuffleArtistsContainer = styled(motion.div)`
  > div {
    margin: auto;
  }
`;

export const InitialImg = styled(Img)`
  > * {
    ${imageSizes};
  }

  img {
    object-fit: cover;
    object-position: 0 0;
    ${imageSizes};
  }
`;

export const ImgInnerContainer = styled.div`
  position: absolute;
  transition: transform 0.2s ease-in-out;
`;

export const ImgContainer = styled.div`
  position: relative;
  ${imageSizes};
`;

export const InitialBG = styled.div`
  background-color: #c4c4c4;
  position: absolute;
  transform: rotate(-5deg);
  transform-origin: center center;
  ${imageSizes};

  &:before,
  &:after {
    position: absolute;
    content: '';
    transform-origin: center center;
    ${imageSizes};
  }

  &:before {
    background-color: #000000;
    transform: rotate(8deg);
  }
`;
