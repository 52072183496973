/* eslint-disable no-useless-escape */
export const fetchVideoIdFromUrl = (url: string): { src: string; type: string } | false => {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  let match = url.match(regExp);

  if (match && match[7].length === 11) {
    return {
      src: `https://www.youtube.com/embed/${match[7]}?rel=0&controls=1&autoplay=1&loop=0&color=red&modestbranding=1`,
      type: 'youtube',
    };
  }

  regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  match = url.match(regExp);

  return match && match[3].length > 1
    ? {
        src: `https://player.vimeo.com/video/${match[3]}?autoplay=1&loop=0&autopause=0&byline=0&portrait=0&title=0&dnt=1&color=white`,
        type: 'vimeo',
      }
    : false;
};
