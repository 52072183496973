import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import sampleSize from 'lodash.samplesize';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Components
import { ShuffleButton, ShuffleImage, Text } from 'components';

// Styles
import { RightToLeftAnimationContainer } from 'styles/animations';

// Local styles
import { ShuffleContainer, ShuffleInnerContainer, ShuffleButtonContainer, ArtistLink } from './shuffle-artists.styles';

// Utils
import { getBreakedText } from 'utils';

// Interfaces
import { ITimeslot } from 'types/interfaces';

interface IShuffleAmountState {
  [key: number]: string[] | undefined;
}

interface IAllArtistData {
  [key: string]: ITimeslot;
}

interface IShuffleArtistsProps {
  allArtistData: IAllArtistData;
  onShuffleComplete?: (timeslot: ITimeslot) => void;
  activeTimeslotId?: string;
  hasClickThrough?: boolean;
}

const ShuffleArtists: React.FC<IShuffleArtistsProps> = ({ allArtistData, onShuffleComplete, activeTimeslotId, hasClickThrough = true }) => {
  const [hasShuffleMounted, setHasShuffleMounted] = useState(false);
  const [animateOnMount] = useState(typeof activeTimeslotId === 'undefined');
  const [shuffleAmount, setShuffleAmount] = useState<IShuffleAmountState>({});
  const [isShuffling, setIsShuffling] = useState<boolean>(false);
  const [currentArtistId, setCurrentArtistId] = useState<string>('');
  const [tempArtistId, setTempArtistId] = useState<string>('');

  const isShufflingTimeout = useRef<any>(null);

  const getRandomArtist = (artistData: IAllArtistData) => {
    // Remove the active artist from array
    const artistArray = Object.keys(artistData).filter((item) => item !== currentArtistId);
    const randomArtists = sampleSize(artistArray, 10);

    let images: string[] = [];

    randomArtists && setTempArtistId(randomArtists[randomArtists.length - 1] as string);

    randomArtists.forEach((key: string) => {
      const image = artistData[key].artists[0].image.formats['desktop'];

      images = [...images, image as string];
    });

    return images;
  };

  const shuffleContainerRef = React.useRef<HTMLDivElement>(null);

  const onShuffle = () => {
    setIsShuffling(true);

    shuffleContainerRef && shuffleContainerRef.current && disableBodyScroll(shuffleContainerRef?.current);

    isShufflingTimeout.current = setTimeout(() => {
      setIsShuffling(false);

      setCurrentArtistId(tempArtistId);

      onShuffleComplete && onShuffleComplete(allArtistData[tempArtistId]);

      if (!hasShuffleMounted) {
        setHasShuffleMounted(true);
      }

      shuffleContainerRef && shuffleContainerRef.current && enableBodyScroll(shuffleContainerRef?.current);
    }, 1900);
  };

  useEffect(() => {
    if (animateOnMount) {
      setShuffleAmount({
        1: getRandomArtist(allArtistData),
      });
    } else {
      if (!hasShuffleMounted) {
        setHasShuffleMounted(true);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(isShufflingTimeout.current);

      isShufflingTimeout.current = null;

      clearAllBodyScrollLocks();
    };
  }, []);

  const addShuffleContainer = () => {
    setShuffleAmount((prevState) => {
      const number: number = Number(Object.keys(prevState as any)[Object.keys(prevState as any).length - 1]) + 1 || 1;

      const newState = prevState;

      if (Object.keys(prevState as any).length >= 3) {
        // If object.keys is biggen than 3, remove the first item so the array stays small
        delete newState[Object.keys(prevState as any)[0] as any];
      }

      return {
        ...newState,
        [number]: allArtistData && getRandomArtist(allArtistData),
      };
    });
  };

  const currentArtist = allArtistData && allArtistData[currentArtistId]?.artists[0];
  const currentStage = allArtistData && allArtistData[currentArtistId];

  return (
    <ShuffleContainer>
      {Object.keys(shuffleAmount).map((key: any) => (
        <ShuffleInnerContainer key={key} ref={shuffleContainerRef}>
          {allArtistData && <ShuffleImage images={shuffleAmount[key]} onAnimate={onShuffle} />}
        </ShuffleInnerContainer>
      ))}

      {currentArtist && hasClickThrough && (
        <ArtistLink to={`/artist/${currentArtist.slug}/${currentArtist.id}?bg=${currentStage?.stage.secondaryColor.slice(1)}`}>
          <Text
            color="white"
            fontSize={{ xs: '24px', md: '42px' }}
            fontWeight="heavy"
            lineHeight="78%"
            top={{ xs: '-2px', md: '-8px' }}
            position="relative"
          >
            {getBreakedText(currentArtist.title)}
          </Text>
        </ArtistLink>
      )}

      {hasShuffleMounted && (
        <ShuffleButtonContainer>
          <AnimatePresence initial>
            <RightToLeftAnimationContainer>
              <ShuffleButton onClick={addShuffleContainer} disabled={isShuffling} hasTheme={!hasClickThrough} />
            </RightToLeftAnimationContainer>
          </AnimatePresence>
        </ShuffleButtonContainer>
      )}
    </ShuffleContainer>
  );
};

export default ShuffleArtists;
