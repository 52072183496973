// Styles
import theme from 'styles/theme';

export const getPageColors = (
  pathname: string
): {
  foreground: string;
  background: string;
} => {
  switch (pathname) {
    case '/':
      return {
        foreground: 'black',
        background: 'black',
      };
    case '/programme':
    case '/discover':
      return {
        foreground: '#E94252',
        background: theme.colors.yellow,
      };

    case '/feed':
    case '/programme/artists':
    default:
      return {
        foreground: theme.colors.yellow,
        background: '#E94252',
      };
  }
};
