import React, { ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';

// Providers
import { ThemeContextProvider } from './ThemeContextProvider';

// Theme
import theme from 'styles/theme';

// AppState
import { StateProvider as AppStateProvider } from 'AppState/AppStateProvider';
import { fetchData } from 'services/fetchService';
import { apiEndpoints } from 'static/apiEndpoints';

const queryClient = new QueryClient();

interface ContextProvidersProps {
  children: ReactNode;
}

/**
 * ContextProviders wrapper component to abstract all providers into a single
 * provider that is used in the App component.
 *
 * @author Matt Hamers
 */
export const ContextProviders: React.FC<ContextProvidersProps> = ({ children }: ContextProvidersProps) => {
  const prefetchTodos = async () => {
    // The results of this query will be cached like a normal query
    await queryClient.prefetchQuery('all-artists', () => fetchData(`${apiEndpoints.getAllArtists}?images=desktop`));
    await queryClient.prefetchQuery(`timetable-az`, () => fetchData(`${apiEndpoints.getTimetableAZ}?images=false`));
    await queryClient.prefetchQuery(`timetable-today`, () => fetchData(apiEndpoints.getTimetableToday));
  };

  useEffect(() => {
    prefetchTodos();
  }, []);

  return (
    <HelmetProvider>
      <AppStateProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeContextProvider theme={theme}>{children}</ThemeContextProvider>

          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </AppStateProvider>
    </HelmetProvider>
  );
};
