// State
import { IAppState, IStateAction } from '../AppState';

// Reducers
import { colorsReducer } from './colorsReducer';
import { streamModalReducer } from './streamModalReducer';
import { mainMenuReducer } from './mainMenuReducer';

const rootReducer = (state: IAppState, action: IStateAction): IAppState => {
  const { colors, streamModal, mainMenu } = state;

  return {
    colors: colorsReducer(colors, action),
    streamModal: streamModalReducer(streamModal, action),
    mainMenu: mainMenuReducer(mainMenu, action),
  };
};

export default rootReducer;
