// Components
import { Flex, Text } from 'components';
import { SlideBase } from '../../SlideBase';

// Lib
import { truncate } from 'lib';

export const TwitterSlide = ({ type, tweet, twitterUser, url, custom, variants, animate }: any) => (
  <a href={url} target="_blank" rel="noreferrer">
    <SlideBase variant={type} whileHover={{ height: 350, width: 520 }} custom={custom} variants={variants} animate={animate}>
      <Flex flexDirection="column" justifyContent="flex-end">
        <Text ml="10px" mr="10px" mb="10px" color="white" lineHeight="78%" fontSize={[2, 2, 5]} fontWeight={750} maxWidth={360}>
          {truncate(tweet.toUpperCase(), 30)}
        </Text>
        <Text ml="10px" mb="20px" fontSize={[0, 0, 2, 2]} fontWeight={700}>
          {twitterUser}
        </Text>
      </Flex>
    </SlideBase>
  </a>
);
