import styled from 'styled-components';

// Interfaces
import { ISystem, system } from 'lib';

// Styles
import { breakpoint } from 'styles/breakpoint';
import { LayoutContainer } from 'styles/containers';

// Components
import { Box } from 'components';

const rowHeight = 84;

export const Container = styled(LayoutContainer)<{ multiplier?: number }>`
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  ${({ multiplier }) => multiplier && `min-height: calc(${multiplier * rowHeight}px + 100px);`}

  ${breakpoint.xl`
    top: -93px;
  `}
`;

export const StagesHeader = styled.header`
  position: relative;
  z-index: 4;
  display: flex;
  margin-bottom: 16px;
  width: 100vw;
  overflow-x: scroll;
  left: calc(50% - 50vw);

  > * {
    white-space: nowrap;

    ${breakpoint.md`
      white-space: normal;
    `}
  }

  ${breakpoint.md`
    overflow: hidden;
    flew-wrap: wrap;
    min-height: 31px;
    width: 100%;
    left: auto;
    padding-left: 59px;
    margin-bottom: 28px;
  `}
`;

export const StagesHeaderInner = styled.div`
  display: flex;
  padding: 0 68px 20px;
`;

export const StageTextContainer = styled.div`
  > * {
    text-transform: uppercase;
  }

  overflow: hidden;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;

  ${breakpoint.md`
    max-width: 168px;
    width: 100%;
    text-align: center;
  `}

  &:not(:last-child) {
    overflow: hidden;
    margin-right: 20px;
  }
`;

export const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;

  ${breakpoint.md`
    position: static;
  `}
`;

export const TimeSlot = styled.div`
  height: ${rowHeight}px;
`;

export const Line = styled.span<{ multiplier: number }>`
  display: block;
  position: absolute;
  width: calc(100% - 46px);
  right: 0;
  height: 1px;

  ${({ multiplier, theme }) => `
    background-color: ${theme.colors.red};
    top: ${multiplier * rowHeight}px;
  `}
`;

export const StageColumn = styled(Box)`
  width: 100vw;
  flex-shrink: 1;

  ${breakpoint.md`
    max-width: 168px;
    width: 100%;
  `}

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const StageItem = styled.div<{ bgColor: string; multiplier?: number }>`
  width: 100%;
  position: relative;

  &:hover {
    &:before {
      transform: scale(1.1);
    }
  }

  ${({ bgColor, multiplier }) => `
    height: ${(multiplier || 1) * rowHeight}px;

    &:before {
      content: '';
      transform-origin: center center;
      transition: transform 0.3s ease-in-out;
      width: 100%;
      top: 5px;
      left: 0;
      height: calc(100% - 10px);
      background-color: ${bgColor};
      position: absolute;
    }
`}
`;

export const Time = styled.span`
  position: relative;
  display: inline-block;
  padding-right: 8px;
  text-transform: uppercase;
  top: -16px;

  ${breakpoint.md`
    padding-right: 20px;
  `};
`;

export const StageContent = styled.div`
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
`;

interface IHeaderButton extends ISystem {
  isActive: boolean;
}

export const HeaderButton = styled.button<IHeaderButton>`
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  height: 17px;
  padding-bottom: 4px;

  ${({ color, theme, isActive }) =>
    `
      opacity: ${isActive ? 1 : 0.4};
      font-size: ${theme.fontSizes[0.5]};
      line-height: 1;
      font-weight: bold;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
        left: 0;
        background-color: ${theme.colors[color]};
      }
    `};

  ${system};
`;
