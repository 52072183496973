import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

// Components
import { Box, Flex, Text, PageTitle, Img, LocationSlider, Footer } from 'components';

// Interfaces
import { IStage, ITimetableToday } from 'types/interfaces';

// Styled components
import { TitleContainer, ContentContainer } from './location-detail-content.styles';

// Styles
import { PageAnimationContainer } from 'styles/animations';

interface ILocaitonDetailContentProps {
  location: IStage;
  timeTable: {
    [key: string]: ITimetableToday;
  };
}

const LocationDetailContent: React.FC<ILocaitonDetailContentProps> = ({ location, timeTable }) => {
  const { title, intro, image } = location;

  const imageSrc = {
    placeholder: image.formats.mobile,
    src: image.formats.desktop,
    sizes: [image.formats.mobile, image.formats.tablet, image.formats.desktop],
    webp: [image.formats['mobile_webp'], image.formats['tablet_webp'], image.formats['desktop_webp']],
  };

  return (
    <PageAnimationContainer>
      <Flex width="100vw" minHeight="100vh" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
        <ContentContainer>
          <Box width={['100%', '100%', '70%']}>
            {intro && (
              <Text
                maxWidth="1200px"
                color="white"
                fontWeight="normal"
                fontSize={{ xs: '2.4rem', sm: 2, lg: 9 }}
                lineHeight="106%"
                letterSpacing="-4%"
                mb={{ xs: '26px' }}
                display="inline-block"
              >
                <SanitizedHTML allowedTags={['a', 'p']} html={intro} />
              </Text>
            )}
          </Box>
          <Flex width={['100%', '100%', '30%']} minHeight="100%" justifyContent="flex-end" alignItems="flex-end">
            <Box width={['250px', '250px', '320px']} height={['230px', '230px', '290px']}>
              <Img name="img" {...imageSrc} />
            </Box>
          </Flex>
        </ContentContainer>
        <TitleContainer>
          <Box>
            <PageTitle noTextBreak title={title} />
          </Box>
        </TitleContainer>
      </Flex>
      <Box my="80px">
        {timeTable &&
          Object.keys(timeTable).map((key: string, index: number) => {
            return <LocationSlider key={index} title={timeTable[key].day} timeTable={timeTable[key]} />;
          })}
      </Box>
      <TitleContainer>
        <Box>
          <PageTitle noTextBreak title={title} />
        </Box>
      </TitleContainer>

      <Box mt="80px">
        <Footer />
      </Box>
    </PageAnimationContainer>
  );
};

export default LocationDetailContent;
