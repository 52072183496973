import React, { useEffect, useState } from 'react';

// Components
import { Modal } from 'components';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Subcomponents
import { StreamModalContent } from './subComponents/StreamModalContent';
import { useLocation } from 'react-router-dom';

const FeedModal: React.FC<IWithAppState> = ({ state: { streamModal }, dispatch }) => {
  const [isOpen, toggle] = useState(false);
  const [heartsAnimation, setHeartsAnimation] = useState(false);
  const { stageSlug } = streamModal;
  const location = useLocation();

  const closeModal = () => {
    dispatch({
      type: streamModalActionTypes.CLOSE_STREAM_MODAL,
    });
  };

  const activateLike = () => {
    setHeartsAnimation(true);

    setTimeout(() => {
      setHeartsAnimation(false);
    }, 1450);
  };

  let setLikeInterval: any = () => setInterval(!heartsAnimation && activateLike, 40000);

  useEffect(() => {
    setLikeInterval();
    return () => {
      clearInterval(setLikeInterval());
      setLikeInterval = 0;
    };
  }, []);

  useEffect(() => {
    toggle(streamModal.isOpen);
  }, [streamModal]);

  useEffect(() => {
    // Scroll window to top on every route change
    window.scrollTo(0, 0);

    // Close modal if url location changes
    closeModal();
  }, [location]);

  return (
    <>
      <Modal isOpen={isOpen} handleClose={closeModal}>
        {stageSlug && <StreamModalContent stageSlug={stageSlug} />}
      </Modal>
    </>
  );
};

export default withAppState(FeedModal);
