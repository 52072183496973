import React from 'react';
import { Helmet } from 'react-helmet-async';

// Assets
import shareImage from 'assets/esns-share-image.jpg';

interface ISEOProps {
  title: string;
  description?: string;
}

const SEO: React.FC<ISEOProps> = ({
  title,
  description = 'Eurosonic Noorderslag is the place to discover artists. ESNS is the biggest showcase festival of Europe and offers a stage to European artists',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="ESNS (Eurosonic Noorderslag)" />
      <meta property="twitter:site" content="ESNS (Eurosonic Noorderslag)" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="og:image" content={shareImage} />
    </Helmet>
  );
};

export default SEO;
