import styled, { css } from 'styled-components';
import { breakpoint } from 'styles';

export const Container = styled.button<{ disabled: boolean; bgColor: string; isSmall?: boolean }>`
  border-radius: 50%;
  text-transform: uppercase;
  text-align: center;
  outline: none;
  width: 80px;
  height: 80px;

  ${breakpoint.md`
    width: 170px;
    height: 170px;
  `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      ${breakpoint.md`
        width: 120px;
        height: 120px;
      `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      transition: opacity 0.3s 0.2 ease-in-out;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    `}


  .notTouchDevice & {
    &:hover {
      &:before {
        width: 110%;
        height: 110%;
      }
    }
  }

  &:before {
    z-index: -1;
    content: '';
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;

    ${({ theme, bgColor }) => `
      background-color: ${theme.colors[bgColor]};
    `};
  }

  span {
    display: block;
  }

  ${({ theme, bgColor }) => `
      background-color: ${theme.colors[bgColor]};
  `};
`;
