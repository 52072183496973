// Styled components
import {
  HeaderWrapper,
  PageWrapper,
  ContentWrapper,
  HeaderImgWrapper,
  HeaderImg,
  BodyImg,
  LoadingText,
  EuroRadioLogo,
} from './loading-screen.styles';

// Components
import { Flex, Text } from 'components';

// Assets
import threeFmLogo from 'assets/loading-screen-3fm-logo-rgb.png';
import esnsLogo from 'assets/loading-screen-esns-logo.png';
import vpro3voor12Logo from 'assets/3voor12-logo.png';
import npo3Logo from 'assets/npo.svg';
import euroRadioLogo from 'assets/euroradio-logo.png';

// Hooks
import { useWindowSize } from 'hooks';

const LoadingScreen: React.FC<{ isVideoLoaded: boolean; state: string }> = ({ isVideoLoaded, state }) => {
  const { height } = useWindowSize();

  const pageWrapperAnimation = {
    visible: {
      width: '100vw',
    },
    hidden: { width: '0vw' },
  };

  const contentAnimation = {
    visible: {
      opacity: 1,
    },
    hidden: { opacity: 0 },
  };

  return (
    <PageWrapper animate={isVideoLoaded ? 'hidden' : 'visible'} variants={pageWrapperAnimation} transition={{ duration: 1.5 }}>
      <HeaderWrapper animate={isVideoLoaded ? 'hidden' : 'visible'} variants={contentAnimation} transition={{ duration: 0.3 }}>
        <HeaderImgWrapper>
          <Text color="white" fontSize={0}>
            EEN INITIATIEF VAN
          </Text>
        </HeaderImgWrapper>

        <HeaderImgWrapper>
          <HeaderImg src={vpro3voor12Logo} alt="vpro 3 voor 12 logo" />
        </HeaderImgWrapper>
        <HeaderImgWrapper>
          <HeaderImg src={npo3Logo} alt="npo 3 logo" />
        </HeaderImgWrapper>
        <HeaderImgWrapper>
          <EuroRadioLogo src={euroRadioLogo} alt="euroradio logo" />
        </HeaderImgWrapper>
      </HeaderWrapper>
      <ContentWrapper
        windowHeight={height}
        animate={isVideoLoaded ? 'hidden' : 'visible'}
        variants={contentAnimation}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Text
          alignSelf="center"
          textAlign="center"
          fontSize={['1.8rem', '1.8rem', '2rem', '2rem']}
          fontWeight={700}
          color="white"
          mb={['30px', '30px', '80px', '80px']}
          mt="200px"
          minWidth="400px"
        >
          {state === 'before' ? '3FM PRESENTEERT EUROSONIC NOORDERSLAG' : '3FM PRESENTEERT EUROSONIC NOORDERSLAG'}
          {state === 'before' && (
            <>
              <br /> 13 - 16 JANUARI 2021
            </>
          )}
        </Text>
        <Flex justifyContent="center">
          <BodyImg src={threeFmLogo} alt="3 voor 12 vpro" />
          <BodyImg src={esnsLogo} alt="3 voor 12 vpro" />
        </Flex>

        <Flex position="absolute" bottom={['20px', '20px', '100px', '100px']} justifyContent="center" width="100%">
          <LoadingText fontSize={[0, 0, 0.5, 0.5]} color="white">
            LOADING
          </LoadingText>
        </Flex>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default LoadingScreen;
