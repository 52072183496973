import React from 'react';
import styled from 'styled-components';

// Utils
import { fetchVideoIdFromUrl } from 'utils';

// Styles
import aspectRatio, { aspectRatioChild } from 'styles/aspectRatio';

export interface IVideoPlayer {
  src?: string;
  vimeoId?: string;
  youtubeId?: string;
  autoPlay?: boolean;
}

const DEV = process.env.NODE_ENV === 'development';

const VideoPlayer: React.FC<IVideoPlayer> = ({ src, vimeoId, youtubeId, autoPlay = true }) => {
  const url = vimeoId
    ? {
        src: `https://player.vimeo.com/video/${vimeoId}?autoplay=${
          autoPlay ? 1 : 0
        }&loop=0&autopause=0&byline=0&portrait=0&title=0&dnt=1&color=white`,
      }
    : youtubeId
    ? {
        src: `https://www.youtube.com/embed/${youtubeId}?rel=0&controls=1&autoplay=${autoPlay ? 1 : 0}&loop=0&color=red&modestbranding=1`,
      }
    : src && fetchVideoIdFromUrl(src);

  if (!url) {
    if (DEV) {
      console.error(`Error loading embed ${src}`);
    }
    return null;
  }

  return (
    <Container>
      <Player width="100%" height="100%" src={url.src} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  width: 100%;
  margin: 0 auto;

  ${aspectRatio(16, 9)};
`;
Container.displayName = 'Container';

const Player = styled.iframe`
  ${aspectRatioChild};
`;
Player.displayName = 'Player';

export default VideoPlayer;
