import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

// Local Styles
import { Container, SelectedMenuBg, MenuButton, MenuList, MenuItem } from './main-menu.styles';

// Components
import { Text } from 'components';

// Hooks
import { useWindowSize } from 'hooks';

// Data
import { mainMenuItems, mainMenuItemsAfter } from 'static/data';

// Types
import { ISettings } from 'types/interfaces';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';
import { fetchData } from 'services/fetchService';

type ILink = 'feed' | 'discover' | 'programme';

let menuBtnsRefMap: {
  [value: string]: HTMLDivElement;
} = {};

const MainMenu: React.FC = () => {
  const windowSize = useWindowSize();
  const location = useLocation();
  const [selected, setSelected] = useState<ILink>();
  const [currentWindowWidth, setCurrentWindowWidth] = useState(windowSize.width);

  const [coordsState, setCoordsState] = useState<{
    width?: number;
    height?: number;
    top?: number;
    left?: number;
  }>({});

  const getselectedMenu = (pathname: string) => {
    if (pathname.includes('programme')) {
      return 'programme';
    } else if (pathname.includes('discover') || pathname.includes('artist') || pathname.includes('news') || pathname.includes('location')) {
      return 'discover';
    } else {
      return 'feed';
    }
  };

  const setSelectedBgPosition = (selected: ILink) => {
    const button = menuBtnsRefMap[selected] as HTMLDivElement;

    const linkCoords = button?.getBoundingClientRect();
    const coords = {
      width: linkCoords?.width,
      height: linkCoords?.height,
      top: button?.offsetTop,
      left: button?.offsetLeft,
    };

    setCoordsState(coords);

    setTimeout(() => {
      setSelected(selected as ILink);
    }, 50);
  };

  // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  // Hooks for when this component mounts for the first time
  useEffect(() => {
    if (settingsData) {
      const initialSelected = getselectedMenu(location.pathname);

      setSelected(initialSelected);

      setTimeout(() => {
        setSelectedBgPosition(initialSelected);
      }, 100);
    }
  }, [location.pathname, settingsData]);

  useEffect(() => {
    // Check if window resize has occured
    if (windowSize.width !== currentWindowWidth) {
      // The code below ensures that selected state has the correct position and size
      selected && setSelectedBgPosition(selected);

      // Update the current window resize
      setCurrentWindowWidth(windowSize.width);
    }
  }, [windowSize.width, currentWindowWidth]);

  const menuItems = settingsData?.data.state === 'after' ? mainMenuItemsAfter : mainMenuItems;

  return (
    <Container>
      <SelectedMenuBg
        width={`${coordsState?.width}px`}
        height={`${coordsState?.height}px`}
        transform={`translate(${coordsState?.left}px, ${coordsState?.top}px)`}
      />
      <MenuList>
        {settingsData &&
          menuItems.map(({ value, label, link }) => (
            <MenuItem key={value}>
              <NavLink to={link}>
                <MenuButton
                  onClick={() => setSelectedBgPosition(value as ILink)}
                  id={value}
                  selected={selected === value}
                  ref={(refEl: HTMLDivElement) => {
                    menuBtnsRefMap = {
                      ...menuBtnsRefMap,
                      [value]: refEl,
                    };
                  }}
                >
                  <Text variant="smallBold">{label}</Text>
                </MenuButton>
              </NavLink>
            </MenuItem>
          ))}
      </MenuList>
    </Container>
  );
};

export default MainMenu;
