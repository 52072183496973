import { BadgeBase } from './badge.styles';
import moment from 'moment';

interface IBadge {
  isLive: boolean;
  startDateTime: string;
  title: string;
}

export const Badge: React.FC<IBadge> = ({ isLive, startDateTime }) => (
  <BadgeBase variant={isLive ? 'isLive' : 'hasUpcoming'}>{isLive ? '● LIVE NOW' : moment(startDateTime).format('HH:mm')}</BadgeBase>
);
