import styled from 'styled-components';

import { Box, Img } from 'components';
import { breakpoint } from 'styles/breakpoint';
import { Text } from 'components';

export const ImageContainer = styled(Box)`
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: -40px;
    left: -40px;
    width: calc(100% + 80px);
    height: calc(100% + 80px);
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 1;
    opacity: 0.25;
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 200px;
  height: 270px;
  cursor: pointer;

  ${breakpoint.md`
    width: 400px;
    height: 570px;
  `}

  &:hover {
    &:before {
      opacity: 0.4;
    }

    span {
      opacity: 1;
    }
  }

  &:before {
    transition: opacity 0.4s ease-in-out;
    content: '';
    opacity: 0;
    background-color: black;

    transform-origin: center center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Image = styled(Img)`
  height: 270px;

  ${breakpoint.md`
    height: 570px;
  `}

  & img,
  picture {
    height: 100%;
    object-fit: cover;
  }
`;

export const ArtistTitle = styled(Text)`
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
`;
