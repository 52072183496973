import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Interfaces
import { ISettings, ITimetableToday } from 'types/interfaces';

// Hooks
import { useIsBreakpoint } from 'hooks';

// Components
import { Box, Flex, Footer, SEO, Spinner, Text } from 'components';

// Local styles
import {
  Container,
  StagesHeader,
  StageTextContainer,
  Column,
  TimeSlot,
  Line,
  Time,
  HeaderButton,
  StagesHeaderInner,
} from './time-table.styles';
import theme from 'styles/theme';

// AppState

import { StageColumnContainer } from './subComponents/StageColumnContainer';

interface ITimeTableProps {
  timeSlots?: string[];
}

const TimeTable: React.FC<ITimeTableProps> = () => {
  // Get feed data
  const { data: timetableData } = useQuery<ITimetableToday | {}, string>(`timetable-today`, () =>
    fetchData(apiEndpoints.getTimetableToday)
  );

  // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  const hasTimetable = timetableData && Object.keys(timetableData).length > 0;

  const { isMobile } = useIsBreakpoint();
  const [currentStage, setCurrentStage] = useState<string | undefined>(
    hasTimetable ? Object.keys((timetableData as ITimetableToday)?.stages)[0] : ''
  );

  useEffect(() => {
    if (timetableData) {
      hasTimetable && setCurrentStage(Object.keys((timetableData as ITimetableToday)?.stages)[0]);
    }
  }, [timetableData]);

  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: '/timetable',
    });
  }, []);

  if (!timetableData || !settingsData) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  const rangeTimetable = settingsData?.data.rangeTimetable;

  if (rangeTimetable) {
    rangeTimetable[rangeTimetable.length - 1] = 'End';
  }

  const stages = (timetableData as ITimetableToday)?.stages;

  return (
    <>
      <SEO title="Programme | Eurosonic Noorderslag (ESNS)" />
      <Container multiplier={rangeTimetable?.length}>
        <StagesHeader>
          {isMobile ? (
            <StagesHeaderInner>
              {stages &&
                Object.keys(stages).map((stage: string) => (
                  <StageTextContainer key={stage}>
                    <HeaderButton onClick={() => setCurrentStage(stage)} color="red" isActive={currentStage === stage}>
                      {stage}
                    </HeaderButton>
                  </StageTextContainer>
                ))}
            </StagesHeaderInner>
          ) : (
            <>
              {stages &&
                Object.keys(stages).map((stage: string) => (
                  <StageTextContainer key={stage}>
                    <Text
                      color="red"
                      fontSize={{
                        xs: '1.6rem',
                        md: '2.1rem',
                      }}
                      fontWeight="heavy"
                    >
                      {stage}
                    </Text>
                  </StageTextContainer>
                ))}
            </>
          )}
        </StagesHeader>

        <Box position="relative" width="100%">
          {(rangeTimetable || []).map((_, i: number) => (
            <Line key={i} multiplier={i} />
          ))}

          <Flex
            justifyContent="flex-start"
            alignItems="flex-start"
            paddingLeft={{
              xs: '46px',
              md: 0,
            }}
          >
            <Column>
              {(rangeTimetable || []).map((slot: string, i: number) => (
                <TimeSlot key={i}>
                  <Time>
                    <Text color="red" fontSize="1.3rem" fontWeight="heavy">
                      {slot}
                    </Text>
                  </Time>
                </TimeSlot>
              ))}
            </Column>

            {stages &&
              Object.keys(stages).map((stage: string, i: number) => (
                <Fragment key={i}>
                  {!isMobile ? (
                    <StageColumnContainer list={stages[stage as keyof typeof stages]} />
                  ) : isMobile && currentStage === stage ? (
                    <StageColumnContainer list={stages[stage as keyof typeof stages]} />
                  ) : null}
                </Fragment>
              ))}
          </Flex>
        </Box>
      </Container>
      <Footer color="red" hoverColor="black" />
    </>
  );
};

export default TimeTable;
