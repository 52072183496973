import React from 'react';

// Components
import { Text } from 'components';

// Local styles
import { Container } from './subtitle.styles';

interface ISubTitleProps {
  title?: string;
  color: string;
}

const SubTitle: React.FC<ISubTitleProps> = ({ title, color }) => (
  <Container mb={{ xs: '40px', md: '72px' }} maxWidth={{ xs: '340px', md: '480px' }} height="44px" padding="0 20px">
    {title && (
      <h2>
        <Text
          color={color}
          fontSize={{ xs: 0, md: 1 }}
          lineHeight={{ xs: '115%', md: '120%' }}
          fontWeight="heavy"
          display="inline-block"
          textAlign="center"
        >
          {title}
        </Text>
      </h2>
    )}
  </Container>
);

export default SubTitle;
