import styled from 'styled-components';

// Components
import { Text } from 'components';

// Styles
import { breakpoint } from 'styles';
import { LayoutContainer } from 'styles/containers';

export const ContentContainer = styled(LayoutContainer)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  will-change: transform;
`;

export const StyledText = styled(Text)``;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;

  ${breakpoint.md`
    flex-direction: row;
    margin-bottom: 140px;
  `}

  ${breakpoint.lg`
    margin-bottom: 220px;
  `}
`;

export const TopSectionLeft = styled.div`
  margin-bottom: 50px;

  ${breakpoint.md`
    margin-bottom: 0;
    width: 50%;
  `}
`;

export const TopSectionRight = styled.div`
  ${breakpoint.md`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(50% - 20px);

  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  right: -20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  ${breakpoint.md`
    margin-top: 200px;
    width: 100%;
    right: -90px;
    bottom: -34px;
  `}
`;
