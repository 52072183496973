import React from 'react';
import { AnimatePresence } from 'framer-motion';

// Components
import Swiper from 'react-id-swiper';
import { Slide } from './Slide';

// Styled components
import { SliderWrapper } from './feed-slider.styles';

export const FeedSlider: React.FC<any> = ({ data }) => {
  // prettier-ignore
  const params = {
    slidesPerView: ("auto" as  number | "auto" | undefined),
    spaceBetween: 0,
    freeMode: true,
    grabCursor: true,

  };

  const variants = {
    visible: (i: number) => ({
      transform: 'translateY(0px)',
      transition: {
        type: 'spring',
        duration: 1.5,
        delay: i * 0.1,
      },
    }),
    hidden: { transform: 'translateY(350px)' },
  };

  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <AnimatePresence initial>
      <SliderWrapper key="1" initial="hidden" animate="visible" variants={list}>
        <Swiper key="1" {...params}>
          {data?.map((item: any, i: number) => (
            <div key={`slide-${i}`} className="slide-container">
              <Slide {...item} custom={i} animate="visible" variants={variants} />
            </div>
          ))}
        </Swiper>
      </SliderWrapper>
    </AnimatePresence>
  );
};
