import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoint } from 'styles/breakpoint';

// Assets
import mobileBg from 'assets/map-building-mobile.svg';
import desktopBg from 'assets/map-building-desktop.svg';

export const MapContainer = styled(motion.div)<any>`
  height: ${({ mobileHeight }) => `${mobileHeight}px`};
  width: 100vw;
  background-image: url('${({ bgImage }) => bgImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  ${breakpoint.md`
    height: 100vh;
    background-position: top;
  `}

  z-index: 10;
`;

export const Gradient = styled.div<any>`
  height: ${({ mobileHeight }) => `${mobileHeight}px`};

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  background: radial-gradient(circle, rgba(233, 66, 82, 0) 0%, rgba(233, 66, 82, 0.7) 0%);
  z-index: 0;
`;

export const RadialGradient = styled.div<any>`
  height: ${({ mobileHeight }) => `${mobileHeight}px`};

  position: absolute;
  content: '';
  top: 0;
  left: 0;
  /* height: 100vh; */
  width: 100vw;
  background: radial-gradient(circle, rgba(233, 66, 82, 0) 0%, rgba(233, 66, 82, 1) 100%);
  z-index: 0;
`;

export const BuildingBackground = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ mobileHeight }) => `${mobileHeight}px`};

  width: 100vw;
  background-image: url('${mobileBg}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;

  ${breakpoint.md`${css`
    background-image: url('${desktopBg}');
    background-position: top;
  `}`}
`;
