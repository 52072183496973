import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import moment from 'moment';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Components
import { Button, Flex, Footer, PageImage, PageVideo, Quote } from 'components';

// Interfaces
import { IArtist, ITimeslot } from 'types/interfaces';

import { ContentContainer, TopSection, TopSectionLeft, TopSectionRight, ImageContainer, StyledText } from './artist-detail-content.styles';

import theme from 'styles/theme';

interface IArtistDetailContentProps extends IWithAppState {
  artist: IArtist;
  timeslots: ITimeslot[];
}

const ArtistDetailContent: React.FC<IArtistDetailContentProps> = ({ artist, timeslots, dispatch }) => {
  const { bio, intro, bookingsLink, spotifyLink, website, quote, quoteBy, video, placeholder, image2, rewatch } = artist;

  const hasRewatchUrl = rewatch;

  if (!timeslots[0]) return null;
  const { isLive, stage } = timeslots[0];

  const openStreamModal = (artistSlug?: string) => {
    dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        artistSlug: artistSlug,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };

  return (
    <Flex width="100vw" minHeight="100vh" justifyContent="flex-start" alignItems="center" flexDirection="column">
      <ContentContainer>
        <TopSection>
          <TopSectionLeft>
            {isLive && (
              <Button
                mb={{ xs: '26px' }}
                iconBefore="play"
                foregroundColor="background"
                backgroundColor="white"
                onClick={() => openStreamModal()}
              >
                Watch now
              </Button>
            )}
            {hasRewatchUrl && (
              <Button
                mb={{ xs: '26px' }}
                iconBefore="play"
                foregroundColor="background"
                backgroundColor="white"
                onClick={() => openStreamModal(artist.slug)}
              >
                Re-watch now
              </Button>
            )}

            {intro && (
              <StyledText
                color="white"
                fontWeight="normal"
                fontSize={{ xs: '2.4rem', sm: 5, lg: 9 }}
                lineHeight="106%"
                letterSpacing="-4%"
                mb={{ xs: '26px' }}
                display="inline-block"
              >
                <SanitizedHTML allowedTags={['a', 'p']} html={intro} />
              </StyledText>
            )}

            <StyledText
              color="white"
              fontWeight="normal"
              fontSize={{ xs: '1.8rem', sm: '2.4rem', lg: '2.8rem' }}
              lineHeight="106%"
              letterSpacing="-4%"
              mb={{ xs: '35px' }}
              display="inline-block"
            >
              <SanitizedHTML allowedTags={['a', 'p']} html={bio} />
            </StyledText>

            <StyledText
              color="white"
              fontWeight="bold"
              fontSize={{ xs: '1.8rem', sm: '2.4rem', lg: '2.8rem' }}
              lineHeight="106%"
              letterSpacing="-4%"
              mb={{ xs: '35px' }}
              display="inline-block"
            >
              {timeslots && `Playing from ${moment(timeslots[0].startDateTime).format('HH:mm')} at ${stage?.title}`}
            </StyledText>

            <Flex mb={{ xs: '35px' }}>
              {website && (
                <a href={website} target="_blank" rel="noreferrer noopener">
                  <Button mr="10px" foregroundColor="background" backgroundColor="foreground" fontWeight="heavy" padding="12px 18px">
                    Website
                  </Button>
                </a>
              )}
              {spotifyLink && (
                <a href={spotifyLink} target="_blank" rel="noreferrer noopener">
                  <Button mr="10px" foregroundColor="background" backgroundColor="foreground" fontWeight="heavy" padding="12px 18px">
                    Spotify
                  </Button>
                </a>
              )}
              {bookingsLink && (
                <a href={bookingsLink} target="_blank" rel="noreferrer noopener">
                  <Button foregroundColor="background" backgroundColor="foreground" fontWeight="heavy" padding="12px 18px">
                    Bookings
                  </Button>
                </a>
              )}
            </Flex>
          </TopSectionLeft>

          <TopSectionRight>
            <ImageContainer>
              <PageImage image={image2} />
            </ImageContainer>
          </TopSectionRight>
        </TopSection>
        {(quoteBy || quote) && (
          <Flex mb={{ xs: '60px', md: '100px', lg: '140px' }} maxWidth={'1100px'} justifyContent="center" width="100%">
            <Quote color="foreground" quoteText={quote} captionText={quoteBy}></Quote>
          </Flex>
        )}

        {video && placeholder && (
          <Flex justifyContent="center" width="100%">
            <PageVideo video={video} image={placeholder} inverted />
          </Flex>
        )}
      </ContentContainer>
      <Footer />
    </Flex>
  );
};

export default withAppState(ArtistDetailContent);
