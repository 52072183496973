import styled from 'styled-components';

export const NavButton = styled.div<{ isInActive?: boolean }>`
  display: flex;

  ${({ isInActive }) =>
    isInActive &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}

  > button {
    outline: none;
  }
`;
