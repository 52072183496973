import React, { Fragment, ReactNodeArray } from 'react';

export const getBreakedText = (text: string): ReactNodeArray => {
  const textArray = text.split(' ');

  return textArray.map((word: string, i: number) => {
    return word === '&' ? (
      <Fragment key={i}>{word} </Fragment>
    ) : (
      <Fragment key={i}>
        {word} <br />
      </Fragment>
    );
  });
};
