import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';

// Theme
import theme from 'styles/theme';

// Hooks
import { useIsBreakpoint } from 'hooks';

// Lib
import { truncate } from 'lib';

// FetchService
import { fetchData } from 'services/fetchService';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Interfaces
import { IStage, ITimeslot } from 'types/interfaces';

// Components
import { Button, Flex, Footer, SEO, Spinner, Text } from 'components';

// Styles
import {
  ListHeading,
  List,
  ListItem,
  Name,
  Description,
  DatePlace,
  Date,
  Place,
  Genere,
  InfoContainer,
  ButtonContainer,
  Container,
  StyledLink,
} from './artist-a-z.styles';

const ArtistAZ: React.FC<IWithAppState> = ({ dispatch }) => {
  const { data } = useQuery<
    {
      [key: string]: ITimeslot[];
    },
    string
  >(`timetable-az`, () => fetchData(`${apiEndpoints.getTimetableAZ}?images=false`));

  const { isMobile } = useIsBreakpoint();

  const openStreamModal = (stage: IStage, artistSlug?: string) => {
    dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        artistSlug: artistSlug,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };

  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: '/artists-az',
    });
  }, []);

  if (!data) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  return (
    <>
      <SEO title="Artists | Eurosonic Noorderslag (ESNS)" />
      <Container>
        {data &&
          Object.keys(data).map((key) => {
            const timeslots = data[key];

            return (
              <Fragment key={key}>
                <ListHeading>{key}</ListHeading>
                <List>
                  {timeslots.map((timeslot: ITimeslot, i: number) => {
                    const { startDateTime, stage, isLive } = timeslot;
                    const { slug, id, title, subTitle, rewatch } = timeslot.artists[0];

                    const hasRewatchUrl = rewatch;

                    return (
                      <ListItem key={i}>
                        <StyledLink to={`/artist/${slug}/${id}?bg=${stage.secondaryColor.slice(1)}`}>
                          <Name>
                            <Text fontSize={[1, 2]} lineHeight="100%" fontWeight="heavy" mb="4px" display="inline-block">
                              {title}
                            </Text>
                          </Name>
                          <InfoContainer>
                            <Name>
                              <Text fontSize={[1, 2]} lineHeight="100%" fontWeight="heavy" display="inline-block">
                                {title}
                              </Text>
                            </Name>

                            <Description>
                              <Genere>{isMobile ? truncate(subTitle, 40) : subTitle}</Genere>
                            </Description>
                          </InfoContainer>

                          {!hasRewatchUrl && !isLive && (
                            <DatePlace>
                              <Date>
                                {
                                  format(
                                    new (window.Date as any)(parseISO(startDateTime)),
                                    isMobile ? 'EE. HH:mm' : 'EEEE. HH:mm'
                                  ) as string
                                }
                              </Date>
                              <Place>{stage.title}</Place>
                            </DatePlace>
                          )}
                        </StyledLink>

                        {(isLive || hasRewatchUrl) && (
                          <ButtonContainer>
                            {isLive && (
                              <Button
                                ml="20px"
                                iconBefore="play"
                                isMobileSmall
                                variantColor="yellow"
                                onClick={() => openStreamModal(stage)}
                              >
                                {isMobile ? 'Watch' : 'Watch now'}
                              </Button>
                            )}

                            {hasRewatchUrl && (
                              <Button
                                ml="20px"
                                iconBefore="play"
                                isMobileSmall
                                variantColor="yellow"
                                onClick={() => openStreamModal(stage, slug)}
                              >
                                {isMobile ? 'Re-watch' : 'Re-watch now'}
                              </Button>
                            )}
                          </ButtonContainer>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </Fragment>
            );
          })}
      </Container>
      <Footer />
    </>
  );
};

export default withAppState(ArtistAZ);
