import { css } from 'styled-components';
import theme from './theme';

export const breakpoint: any = Object.keys(theme.breakpoints).reduce((accumulator: any, label: any) => {
  accumulator[label] = (...args: any) => css`
    @media (min-width: ${(theme.breakpoints as any)[label]}) {
      ${css({}, ...args)};
    }
  `;
  return accumulator;
}, {});
