import React, { ReactNode, useEffect, useState } from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { AnimatePresence } from 'framer-motion';

// Theme
import theme from 'styles/theme';

// Components
import { Button } from 'components';

// Hooks
import { useWindowSize } from 'hooks';

// Local styles
import { Overlay, ModalContainer, ButtonWrapper, ModalContent } from './modal.styles';

interface IModal {
  handleClose: () => void;
  children: ReactNode;
  isOpen: boolean;
}

const Modal: React.FC<IModal> = ({ handleClose, children, isOpen }) => {
  const windowSize = useWindowSize();
  const [isMobileView, setIsMobileView] = useState<boolean>();

  const modalContainerRef = React.useRef<HTMLDivElement>(null);

  // 768
  const mdBreakpoint = Number(theme.breakpoints[2].slice(0, -2));

  useEffect(() => {
    if (isOpen) {
      modalContainerRef && modalContainerRef.current && disableBodyScroll(modalContainerRef?.current);
    } else {
      modalContainerRef && modalContainerRef.current && enableBodyScroll(modalContainerRef?.current);
    }

    return () => {
      modalContainerRef && modalContainerRef.current && enableBodyScroll(modalContainerRef?.current);
    };
  }, [isOpen]);

  useEffect(() => {
    setIsMobileView(Number(windowSize?.width) < mdBreakpoint);
  }, [windowSize.width]);

  const modalOverlayVariant = {
    initial: { opacity: 0 },
    isOpen: { opacity: 1 },
    exit: { opacity: 0 },
  };
  const modalContainerVariant = {
    initial: { top: '100vh', transition: { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] } },
    isOpen: { top: isMobileView ? '78px' : '58px', transition: { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] } },
    exit: { top: '100vh' },
  };

  const ButtonWrapperVariant = () => ({
    initial: { top: '-100px', transition: { duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] } },
    isOpen: { top: isMobileView ? '14px' : '90px', transition: { duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] } },
    exit: { top: '-100px' },
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay key="1" initial={'initial'} animate={'isOpen'} exit={'exit'} variants={modalOverlayVariant} onClick={handleClose}>
          <ButtonWrapper variants={ButtonWrapperVariant()}>
            <Button
              onClick={handleClose}
              variant="circle"
              iconAfter="close"
              foregroundColor="modalBackground"
              backgroundColor="modalForeground"
            />
          </ButtonWrapper>
          <ModalContainer
            ref={modalContainerRef}
            variants={modalContainerVariant}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ModalContent>{children}</ModalContent>
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export default Modal;
