import styled from 'styled-components';

// Utils
import { breakpoint } from './breakpoint';

export const LayoutContainer = styled.section`
  padding: 0 20px;

  ${breakpoint.md`
    padding: 0 70px;
  `}
`;
