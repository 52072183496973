import styled from 'styled-components';
import { breakpoint } from 'styles';
import { LayoutContainer } from 'styles/containers';

export const TitleContainer = styled.div`
  width: 100vw;
  left: calc(50% - 50vw);
  margin-top: 150px;
  ${breakpoint.md`
    margin-top: 150px;
  `}
`;

export const ContentContainer = styled(LayoutContainer)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 50px;
  padding-bottom: 20px;

  ${breakpoint.md`
    margin-top: 80px;
    padding-bottom: 50px;
    flex-direction: row;
  `}
`;
