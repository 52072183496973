import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Hooks
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Components
import { Flex, SEO, Spinner } from 'components';

// Interfaces
import { IStage, ITimetableToday } from 'types/interfaces';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { colorsActionTypes } from 'AppState/reducers/colorsReducer';
import { mainMenuActionTypes } from 'AppState/reducers/mainMenuReducer';

// Theme
import theme from 'styles/theme';
import LocationDetailContent from './subComponents/LocationDetailContent';

// Libs
import { truncate } from 'lib';

// Utils
import { stripTags } from 'utils';

const Location: React.FC<IWithAppState> = ({ dispatch }) => {
  const { slug } = useParams<{ id: string; slug: string }>();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bgColor: string | null = searchParams.get('bg');

  const { data: locationData, status: locationDataStatus } = useQuery<IStage, string>(`location/${slug}`, () =>
    fetchData(apiEndpoints.getStage + slug + '.json')
  );

  const { data: timeTableData } = useQuery<{ [key: string]: ITimetableToday }, string>(`location/${slug}/timetable`, () =>
    fetchData(apiEndpoints.getTimeTableStage + slug + '.json')
  );

  useEffect(() => {
    if (bgColor) {
      dispatch({
        type: colorsActionTypes.SET_COLORS,
        payload: {
          foreground: theme.colors.yellow,
          background: `#${bgColor}`,
        },
      });
    }
  }, [bgColor]);

  useEffect(() => {
    if (locationData) {
      // Init page view for tracking
      (window as any).NPO?.view({
        pagina: `/location/${locationData.slug}?bg=${bgColor}`,
      });
    }
  }, [locationData]);

  useEffect(() => {
    dispatch({
      type: mainMenuActionTypes.HIDE_MAIN_MENU,
    });

    return () => {
      dispatch({
        type: mainMenuActionTypes.SHOW_MAIN_MENU,
      });
    };
  }, []);

  // Redirect to Error page when the status is error
  useEffect(() => {
    if (locationDataStatus === 'error') {
      history.push('/404');
    }
  }, [locationDataStatus]);

  if (!locationData || !timeTableData) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  return (
    <>
      <SEO title={`${locationData.title} | Eurosonic Noorderslag (ESNS)`} description={truncate(stripTags(locationData.intro), 140)} />
      <LocationDetailContent location={locationData} timeTable={timeTableData} />;
    </>
  );
};

export default withAppState(Location);
