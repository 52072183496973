import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoint } from 'styles';

export const StyledImage = styled.img<{ num: number; hasLoaded: boolean }>`
  opacity: 0;
  max-width: none;
  object-fit: cover;
  position: absolute;
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  transform-origin: center center;
  -webkit-backface-visibility: hidden;
  will-change: transform, opacity;
  width: 265px;
  height: 265px;

  ${breakpoint.md`
    width: 400px;
    height: 400px;
  `}

  ${({ num }) =>
    num === 0
      ? `
  transform: rotate(4deg)`
      : num === 1
      ? `
      transform: rotate(-4deg)`
      : num === 2
      ? `
      transform: rotate(-2deg)`
      : num === 3
      ? `
      transform: rotate(9deg)`
      : num === 4
      ? `
      transform: rotate(-3deg)`
      : num === 5
      ? `
      transform: rotate(-7deg)`
      : num === 6
      ? `
      transform: rotate(5deg)`
      : num === 7
      ? `
      transform: rotate(-5deg)`
      : num === 8
      ? `
      transform: rotate(6deg)`
      : num === 9
      ? `
      transform: rotate(-2.7deg)`
      : ``};

  ${({ hasLoaded, num }) =>
    hasLoaded &&
    `
    transition-delay: ${0.5 + num * 0.12}s;
    opacity: 1;

  `}
`;

export const ImagesContainer = styled(motion.div)`
  width: 265px;
  height: 265px;

  ${breakpoint.md`
    width: 400px;
    height: 400px;
  `}
`;
