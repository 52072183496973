import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { NavLink, useLocation } from 'react-router-dom';

// Components
import { MainMenu, Icon } from 'components';

// Utils
import { getPageColors } from 'utils';

// Styles
import { Container, LeftWrapper, LogoContainer, NpoWrapper, MenuContainer, ExternalLink, CloseButton } from './header.styles';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { colorsActionTypes } from 'AppState/reducers/colorsReducer';

// Theme
import theme from 'styles/theme';

// Assets
import threeFmLogo from 'assets/3fm-logo.png';

const Header: React.FC<IWithAppState> = ({ dispatch, state }) => {
  const { pathname } = useLocation();
  const { mainMenu } = state;

  const delta = 60;

  const [didScroll, setDidScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
  const [navbarHeight, setNavbarHeight] = useState<number | undefined>();

  const headerRef = useRef<HTMLElement>(null);

  const hasScrolled = () => {
    const st = window.scrollY;

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    if (navbarHeight === undefined) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    if (st > lastScrollTop && st > navbarHeight) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }

    setLastScrollTop(st);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setDidScroll(true);
    });

    setNavbarHeight(headerRef?.current?.scrollHeight);
  }, []);

  useEffect(() => {
    if (didScroll) {
      hasScrolled();
      setDidScroll(false);
    }
  }, [didScroll]);

  useEffect(() => {
    // Set correct pageColors bases on the pathname

    if (
      pathname === '/' ||
      pathname === '/feed' ||
      pathname === '/programme' ||
      pathname === '/discover' ||
      pathname === '/programme/artists'
    ) {
      dispatch({
        type: colorsActionTypes.SET_COLORS,
        payload: getPageColors(pathname),
      });
    } else if (pathname.includes('news')) {
      dispatch({
        type: colorsActionTypes.SET_COLORS,
        payload: {
          foreground: '#E94252',
          background: theme.colors.yellow,
        },
      });
    }

    // Clear all body locks on route change
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <Container ref={headerRef}>
      {mainMenu.isVisible && (
        <MenuContainer>
          <MainMenu />
        </MenuContainer>
      )}
      <LogoContainer hide={scrollDirection === 'down'}>
        <LeftWrapper>
          <Icon
            icon="logo"
            to="/feed"
            color="foreground"
            fontSize={{
              xs: '24px',
              md: '28px',
            }}
          />
          <NpoWrapper color="foreground">
            <span>Powered by</span>
            <a href="https://www.npo3fm.nl/" target="_blank" rel="noreferrer noopener">
              <img src={threeFmLogo} alt="npo 3 fm" />
            </a>
          </NpoWrapper>
        </LeftWrapper>

        {mainMenu.isVisible && (
          <ExternalLink href="https://esns.nl/" target="_blank" rel="noreferrer noopener" color="foreground">
            TO ESNS.nL
          </ExternalLink>
        )}

        {!mainMenu.isVisible && (
          <NavLink to="/feed">
            <CloseButton variant="circle" iconAfter="close" type="button" foregroundColor="background" backgroundColor="foreground" />
          </NavLink>
        )}
      </LogoContainer>
    </Container>
  );
};

export default withAppState(Header);
