import React from 'react';
/**
 * Temporary StyleGuide page to test and display all styles & components
 *
 */

// Components
import { Box, Button, Flex, Text } from 'components';
import { Icon } from 'components/common/Icon';

const allIcons = require
  .context('../components/common/Icon/library', false, /\.svg$/)
  .keys()
  .map((k) => k.replace('./', '').replace('.svg', ''));

const StyleGuide: React.FC = () => (
  <Box minHeight="100vh" backgroundColor="white">
    <Flex p="20px" flexDirection="column">
      <Text color="red" fontSize={5} fontWeight="normal">
        This is a text in fontWeight: Normal
      </Text>
      <Text color="red" fontSize={5} fontWeight="bold">
        This is a text in fontWeight: Bold
      </Text>
      <Text color="red" fontSize={5} fontWeight="heavy">
        This is a text in fontWeight: Heavy
      </Text>
    </Flex>
    <Flex p="20px" flexDirection="column">
      <Text color="red" fontSize={0}>
        This is a text in fontSize: 0
      </Text>
      <Text color="red" fontSize={1}>
        This is a text in fontSize: 1
      </Text>
      <Text color="red" fontSize={2}>
        This is a text in fontSize: 2
      </Text>
      <Text color="red" fontSize={3}>
        This is a text in fontSize: 3
      </Text>
      <Text color="red" fontSize={4}>
        This is a text in fontSize: 4
      </Text>
      <Text color="red" fontSize={5}>
        This is a text in fontSize: 5
      </Text>
      <Text color="red" fontSize={6}>
        This is a text in fontSize: 6
      </Text>
      <Text color="red" fontSize={7}>
        This is a text in fontSize: 7
      </Text>
      <Text color="red" fontSize={8}>
        This is a text in fontSize: 8
      </Text>
    </Flex>

    <Flex>
      <Button ml="20px" variantColor="red">
        variantColor: red
      </Button>
      <Button ml="20px" variantColor="yellow">
        variantColor: yellow
      </Button>
      <Button ml="20px" variantColor="white">
        variantColor: white
      </Button>
      <Button ml="20px" variant="outline" variantColor="red">
        variant: outline
      </Button>
      <Button ml="20px" variant="outline" variantColor="yellow">
        variant: outline
      </Button>
      <Button ml="20px" iconBefore="play" variantColor="yellow">
        variant: iconBefore
      </Button>
      <Button ml="20px" iconAfter="play" variant="outline" variantColor="yellow">
        variant: iconAfter
      </Button>
      <Button ml="20px" iconBefore="play" variantColor="white">
        Watch now
      </Button>
    </Flex>

    <Box m="20px" mt="40px">
      <Text color="red" fontSize={5} fontWeight="bold">
        Icon Library
      </Text>

      <Flex>
        {allIcons.map((name) => (
          <tr
            style={{
              padding: '8px 0px',
            }}
            key={name}
          >
            <td>
              <Text mr="20px" color="red" fontSize="20px" lineHeight={1}>
                {name}
              </Text>
            </td>
            <td>
              <Icon icon={name} color="red" mr="10px" fontSize="20px" />
            </td>
            <td>
              <Icon icon={name} color="yellow" mr="10px" fontSize="20px" />
            </td>
            <td>
              <Icon icon={name} color="pink" mr="10px" fontSize="20px" />
            </td>
          </tr>
        ))}
      </Flex>

      <Text color="red" fontSize={5} fontWeight="bold">
        Icon displayed as:
      </Text>

      <Flex flexDirection="column">
        <Box>
          <Icon icon={'play'} href="/" color="red" mr="20px" fontSize="20px" />
          <Text mr="20px" color="red" fontSize="20px" lineHeight={1}>
            link
          </Text>
        </Box>
        <Box>
          <Icon icon={'play'} onClick={() => alert('clicked!')} color="red" mr="20px" fontSize="20px" />
          <Text mr="20px" color="red" fontSize="20px" lineHeight={1}>
            button
          </Text>
        </Box>
        <Box>
          <Icon icon={'play'} as="h1" color="red" mr="20px" fontSize="20px" />
          <Text mr="20px" color="red" fontSize="20px" lineHeight={1}>
            any semantic e.g. h1
          </Text>
        </Box>
      </Flex>
    </Box>
  </Box>
);

export default StyleGuide;
