import styled from 'styled-components';

// Components
import { Box } from 'components';

export const Container = styled(Box)`
  padding: 20px;
  box-sizing: content-box;
  margin: auto;
  text-align: center;
`;
