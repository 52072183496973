export const mainMenuItems = [
  {
    value: 'feed',
    label: 'Live Feed',
    link: '/feed',
  },

  {
    value: 'discover',
    label: 'discover',
    link: '/discover',
  },
  {
    value: 'programme',
    label: 'Programme',
    link: '/programme',
  },
];

export const mainMenuItemsAfter = [
  {
    value: 'discover',
    label: 'discover',
    link: '/discover',
  },
  {
    value: 'programme',
    label: 'Re-watch ESNS 2021',
    link: '/programme/artists',
  },
];
