import styled from 'styled-components';

// Components
import { Box } from 'components';

// Styles
import { breakpoint } from 'styles/breakpoint';

const rowHeight = 84;

export const Label = styled.span`
  position: absolute;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  right: 3px;
  top: 8px;

  ${({ theme }) => `
    background-color: ${theme.colors.background};
  `}
`;

export const StageColumn = styled(Box)`
  width: 100vw;
  flex-shrink: 1;

  ${breakpoint.md`
    max-width: 168px;
    width: 100%;
  `}

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const StageItem = styled.div<{ bgColor: string; multiplier?: number }>`
  width: 100%;
  position: relative;
  cursor: pointer;

  ${breakpoint.md`
    &:hover {
      &:before {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        left: -5px;
        top: -5px;
      }
    }
  `};

  ${({ bgColor, multiplier }) => `
    height: ${(multiplier || 1) * rowHeight}px;

    &:before {
      content: '';
      transform-origin: center center;
      transition: all 0.3s ease-in-out;
      width: 100%;
      top: 5px;
      left: 0;
      height: calc(100% - 10px);
      background-color: ${bgColor};
      position: absolute;
    }
`}
`;

export const StageContent = styled.div`
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
`;
