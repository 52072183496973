import styled from 'styled-components';

// Styles
import aspectRatio, { aspectRatioChild } from 'styles/aspectRatio';

// Components
import { Img } from 'components';

export const VideoContainer = styled.div`
  max-width: 1260px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  ${({ theme }) => `
    background-color: ${theme.colors.foreground};
`};
`;

export const StyledImg = styled(Img)`
  position: absolute !important;
  ${aspectRatioChild};

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;

  ${aspectRatio(16, 9)};

  > * {
    ${aspectRatioChild};
  }
`;

export const ButtonContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
