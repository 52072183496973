import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Styled components
import { MapContainer, VideoContainer, VideoPlayer } from './MapAnimation.styles';

// Hooks
import { useIsBreakpoint } from 'hooks';
import { LoadingScreen } from 'pages/Welcome/subComponents';

// Components
import { Flex } from 'components';

// Types
import { ISettings } from 'types/interfaces';

export const MapAnimation: React.FC<any> = ({ data: { state } }) => {
  const history = useHistory();
  const { isMobile } = useIsBreakpoint();

  const [timeOutDone, setTimeOutDone] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [play, setPlay] = useState(false);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const onVideoEnded = () => {
    history.push('/feed');
  };

  const onWaitingRedirectTimeout = useRef<any>(null);

  useEffect(() => {
    onWaitingRedirectTimeout.current = setTimeout(() => {
      history.push('/feed');
    }, 5000);
  }, []);

  useEffect(() => {
    if (isVideoLoaded) {
      setTimeout(() => {
        setTimeOutDone(true);

        if (state === 'after') {
          history.push('/feed');
        } else {
          setTimeout(() => {
            setPlay(true);
          }, 50);
        }
      }, 2000);
    }
  }, [isVideoLoaded]);

  const mobileVideo = 'https://content.omroep.nl/3fm/lowlands/2020/videos/intro-video/mobile-intro.mp4';
  const desktopVideo = 'https://content.omroep.nl/3fm/lowlands/2020/videos/intro-video/desktop-intro.mp4';

  return (
    <MapContainer>
      <Flex justifyContent="center" width="100vw">
        <LoadingScreen isVideoLoaded={timeOutDone} state={state} />
      </Flex>
      <VideoContainer>
        <VideoPlayer
          width="100%"
          height="100%"
          muted
          playsinline
          preload="auto"
          playing={play}
          onStart={() => clearTimeout(onWaitingRedirectTimeout.current)}
          onEnded={onVideoEnded}
          onReady={onLoadedData}
          url={isMobile ? mobileVideo : desktopVideo}
        />
      </VideoContainer>
    </MapContainer>
  );
};
