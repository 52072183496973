import React, { useState } from 'react';

// Components
import { Button, VideoPlayer } from 'components';

// Interfaces
import { IImage, IVideo } from 'types/interfaces';

// Local Styles
import { VideoContainer, ImageContainer, StyledImg, ButtonContainer } from './page-video.styles';

interface IPageVideoProps {
  image?: IImage;
  video: IVideo;
  inverted?: boolean;
}

const PageVideo: React.FC<IPageVideoProps> = ({ video, image, inverted }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const imageSrc = image
    ? {
        placeholder: image?.formats.mobile,
        src: image?.formats.desktop,
        sizes: [image?.formats.mobile, image?.formats.tablet, image?.formats.desktop],
        webp: [image?.formats['mobile_webp'], image?.formats['tablet_webp'], image?.formats['desktop_webp']],
      }
    : {};

  return (
    <VideoContainer>
      {!isVideoPlaying && (
        <>
          <ImageContainer>{imageSrc.sizes && <StyledImg name="img" {...imageSrc} />}</ImageContainer>
          <ButtonContainer>
            <Button
              ml="20px"
              foregroundColor={inverted ? 'background' : 'foreground'}
              backgroundColor={inverted ? 'foreground' : 'background'}
              fontWeight="heavy"
              padding="12px 18px"
              onClick={() => setIsVideoPlaying(true)}
            >
              Play video
            </Button>
          </ButtonContainer>
        </>
      )}

      {isVideoPlaying && <VideoPlayer src={video.initiator} />}
    </VideoContainer>
  );
};

export default PageVideo;
