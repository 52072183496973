import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';

import { mainMenuActionTypes } from 'AppState/reducers/mainMenuReducer';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Hooks
import { useHistory, useParams } from 'react-router-dom';

// Components
import { Flex, Spinner, Footer, SEO } from 'components';
import { NewsDetailContent } from './subComponents/NewsDetailContent';

// Styles
import { PageAnimationContainer } from 'styles/animations';

// Lib
import { truncate } from 'lib';

// Utils
import { stripTags } from 'utils';

// Interfaces
import { INewsItem } from 'types/interfaces';

const NewsDetail: React.FC<IWithAppState> = ({ dispatch }) => {
  const { id, slug } = useParams<{ id: string; slug: string }>();

  const history = useHistory();

  const { data: newsItemData, status: newsItemDataStatus } = useQuery<INewsItem, string>(`news/${slug}`, () =>
    fetchData(apiEndpoints.getNewsArticle + slug)
  );

  useEffect(() => {
    dispatch({
      type: mainMenuActionTypes.HIDE_MAIN_MENU,
    });

    return () => {
      dispatch({
        type: mainMenuActionTypes.SHOW_MAIN_MENU,
      });
    };
  }, []);

  useEffect(() => {
    if (newsItemData) {
      // Init page view for tracking
      (window as any).NPO?.view({
        pagina: `/news/${newsItemData.slug}/${id}`,
      });
    }
  }, [newsItemData]);

  // Redirect to Error page when the status is error
  useEffect(() => {
    if (newsItemDataStatus === 'error') {
      history.push('/404');
    }
  }, [newsItemDataStatus]);

  if (!newsItemData) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  return (
    <PageAnimationContainer>
      <SEO title={`${newsItemData.title} | Eurosonic Noorderslag (ESNS)`} description={truncate(stripTags(newsItemData.text), 140)} />
      <NewsDetailContent newsItem={newsItemData} />
      <Footer color="red" hoverColor="black" />
    </PageAnimationContainer>
  );
};

export default withAppState(NewsDetail);
