import React from 'react';

import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import { Icon } from 'components';

// Base components
import { ButtonBase } from './ButtonBase';

export type SystemProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;

interface ButtonProps extends Omit<Omit<React.HTMLAttributes<HTMLButtonElement>, 'color'>, 'css'>, SystemProps {
  isLoading?: boolean;
  isFullWidth?: boolean;
  isMobileSmall?: boolean;
  baseColor?: string;
  disabled?: boolean;
  spinner?: JSX.Element;
  variantColor?: string;
  foregroundColor?: string;
  backgroundColor?: string;
  variant?: string;
  type?: string;
  ref?: React.Ref<HTMLButtonElement>;
  iconBefore?: string;
  iconAfter?: string;
}

export const Button: React.FC<ButtonProps> = React.forwardRef(function Button(
  {
    isLoading,
    disabled,
    children,
    variantColor = 'default',
    variant = 'default',
    iconBefore,
    iconAfter,
    isMobileSmall,
    foregroundColor,
    backgroundColor,
    ...props
  },
  ref
) {
  const _props = {
    ...props,
    disabled: disabled || isLoading,
    isMobileSmall,
    variantColor,
    foregroundColor,
    backgroundColor,
    iconBefore,
    iconAfter,
    variant,
    ref,
  };

  const iconProps = {
    mr: iconAfter ? 0 : isMobileSmall ? ['6px', '10px'] : variant === 'circle' ? 0 : '10px',
    ml: !iconAfter ? 0 : isMobileSmall ? ['6px', '10px'] : variant === 'circle' ? 0 : '10px',
    fontSize:
      iconAfter === 'heart'
        ? { xs: '1.5rem', md: '3.0rem' }
        : isMobileSmall
        ? ['1.0rem', '1.4rem']
        : variant === 'circle'
        ? { xs: '1.5rem', md: '1.8rem' }
        : '1.4rem',
  };

  const btnStyleProps = {
    fontSize: isMobileSmall ? ['1.1rem', '1.4rem'] : '1.4rem',
    padding: isMobileSmall ? ['7px 10px;', '14px 18px'] : '14px 18px',
  };

  return (
    <ButtonBase {..._props} {...btnStyleProps}>
      {iconBefore && <Icon icon={iconBefore} {...iconProps} />}
      {children}
      {iconAfter && <Icon icon={iconAfter} {...iconProps} />}
    </ButtonBase>
  );
});
