import styled, { css } from 'styled-components';
import { Box } from 'components';
import { motion } from 'framer-motion';

import theme from 'styles/theme';
import { breakpoint } from 'styles/breakpoint';

export const LocationPinContainer = styled(Box)`
  position: absolute;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1000;
`;

export const PinWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${breakpoint.md`
     flex-direction: row;
     align-items: center;
  `}
`;

export const Pulse = styled(motion.div)`
  position: relative;
  background: ${theme.colors.yellow};
  border-radius: 50%;
  width: 25px;
  height: 25px;
`;

export const HoverPulse = styled(motion.div)`
  background: ${theme.colors.yellow};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: 100;
  position: absolute;
  top: 0;
`;

export const Logo = styled(motion.img)`
  position: absolute;
  top: -12px;
  left: -18px;
  z-index: 1000;
  height: 50px;
  min-width: 50px;

  ${breakpoint.md`
       position: absolute;
       top: -12px;
       left: -18px;
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: ${theme.fontSizes[0.5]};
  color: ${theme.colors.yellow};
  font-weight: 750;
  margin-left: 5px;

  ${breakpoint.md`${css`
    font-size: ${theme.fontSizes[2]};
  `}`}
`;

export const LiveBadge = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 18px;

  background-color: ${theme.colors.yellow};
  color: ${theme.colors.red};
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 0.015em;

  ${breakpoint.md`${css`
    height: 20px;
    padding: 0 5px;
    font-size: ${theme.fontSizes[0]};
  `}`}
`;
