import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Components
import { Button, VideoPlayer, Text, StreamSlider, Box, Flex } from 'components';

// Local styles
import {
  VideoContainer,
  HeartZoomContainer,
  TitleContainer,
  HeartButton,
  DesktopText,
  ArtistButtonContainer,
  HeartsAnimation,
  Heart1,
  Heart2,
  Heart3,
  Heart4,
  Heart5,
  Heart6,
} from './stream-modal-content.styles';

// Utils
import { getBreakedText } from 'utils';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';

// Interfaces
import { IStage } from 'types/interfaces';
import { IArtistData } from 'pages/ArtistDetail/ArtistDetail';

interface IStreamModalContentProps extends IWithAppState {
  stageSlug: string;
}

const StreamModalContent: React.FC<IStreamModalContentProps> = ({ state: { streamModal } }) => {
  const [heartsAnimation, setHeartsAnimation] = useState(false);

  const { stageSlug, artistSlug } = streamModal;

  const { data: stageData } = useQuery<IStage, string>(`stage/${stageSlug}`, () => fetchData(apiEndpoints.getStage + stageSlug + '.json'));

  const { data: artistData } = useQuery<IArtistData, string>(`artist/${artistSlug}`, () => fetchData(apiEndpoints.getArtist + artistSlug + '.json'), {
    enabled: artistSlug !== undefined,
  });

  const heartsTimer = useRef<any>(null);
  const likeInterval = useRef<any>(null);

  const artist = artistSlug ? artistData?.artist : stageData?.nowLive?.artists[0];
  const zoomUrl = !artistSlug && stageData?.nowLive?.zoomUrl;
  const videoUrl = stageData?.stream?.initiator;
  const vimeoId = artistSlug ? artistData?.artist?.rewatch?.data?.vimeo_id : stageData?.stream?.data?.vimeo_id;
  const youtubeId = artistSlug ? artistData?.artist?.rewatch?.data?.youtube_id : stageData?.stream?.data?.youtube_id;
  const stageColor = artistSlug ? artistData?.timeslots[0].stage.secondaryColor : stageData?.nowLive?.stage.secondaryColor;

  const activateLike = () => {
    setHeartsAnimation(true);

    heartsTimer.current = setTimeout(() => {
      setHeartsAnimation(false);
    }, 1450);
  };

  useEffect(() => {
    if (stageData && !artistSlug) {
      // Init page view for tracking liveStream

      (window as any).NPO?.view({
        pagina: `/livestream/${stageData.slug}/${stageData.id}`,
      });
    }
  }, [stageData]);

  useEffect(() => {
    if (artistData) {
      // Init page view for tracking liveStream artist rewatch

      (window as any).NPO?.view({
        pagina: `/rewatch/${artist?.slug}/${artist?.id}`,
      });
    }
  }, [artistData]);

  useEffect(() => {
    return () => {
      clearTimeout(heartsTimer.current);
    };
  }, []);

  useEffect(() => {
    likeInterval.current = setInterval(!heartsAnimation && activateLike, 10000);

    return () => {
      clearInterval(likeInterval.current);
    };
  }, []);

  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <TitleContainer>
        {artist?.title && (
          <Text color="modalForeground" fontSize={{ xs: 10, sm: 11, lg: 12, xl: 13 }} lineHeight="80%" fontWeight="heavy">
            {getBreakedText(artist?.title as string)}
          </Text>
        )}
      </TitleContainer>

      <VideoContainer>
        {(vimeoId || youtubeId) && <VideoPlayer vimeoId={vimeoId} youtubeId={youtubeId} autoPlay={!artistSlug} />}
        <HeartZoomContainer>
          {videoUrl && !artistSlug && (
            <HeartButton
              disabled={heartsAnimation}
              height={{ xs: '40px', md: '80px' }}
              width={{ xs: '40px', md: '80px' }}
              variant="circle"
              iconAfter="heart"
              foregroundColor="modalBackground"
              backgroundColor="modalForeground"
              onClick={activateLike}
            />
          )}

          {zoomUrl && (
            <a href={zoomUrl} target="_blank" rel="noreferrer noopener">
              <Button
                ml="20px"
                iconAfter="arrowup"
                foregroundColor="modalBackground"
                backgroundColor="modalForeground"
                fontWeight="heavy"
                padding="12px 18px"
              >
                Join Zoom <DesktopText> Room</DesktopText>
              </Button>
            </a>
          )}
        </HeartZoomContainer>

        {!artistSlug && heartsAnimation && (
          <HeartsAnimation>
            <Heart1 icon="heart" color="white" mr="10px" fontSize={{ xs: '40px', md: '50px' }} />
            <Heart2 icon="heart" color="white" mr="10px" fontSize={{ xs: '32px', md: '40px' }} />
            <Heart3 icon="heart" color="white" mr="10px" fontSize={{ xs: '50px', md: '72px' }} />
            <Heart4 icon="heart" color="white" mr="10px" fontSize={{ xs: '30px', md: '38px' }} />
            <Heart5 icon="heart" color="white" mr="10px" fontSize={{ xs: '32px', md: '40px' }} />
            <Heart6 icon="heart" color="white" mr="10px" fontSize={{ xs: '36px', md: '44px' }} />
          </HeartsAnimation>
        )}
      </VideoContainer>

      {artist?.carousel && (
        <>
          <Box width="100%" position="relative">
            <StreamSlider slides={artist?.carousel as any} />
          </Box>

          <ArtistButtonContainer>
            <NavLink to={`/artist/${artist?.slug}/${artist?.id}?bg=${stageColor?.slice(1)}`}>
              <Button variant="outline" foregroundColor="white" backgroundColor="modalBackground">
                Artist Profile
              </Button>
            </NavLink>
          </ArtistButtonContainer>
        </>
      )}
    </Flex>
  );
};

export default withAppState(StreamModalContent);
