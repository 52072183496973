import styled from 'styled-components';

// Components
import { Flex, Text, Img } from 'components';
import { SlideBase } from '../../SlideBase';

// Lib
import { truncate } from 'lib';

export const Image = styled(Img)<any>`
  object-fit: contain;
`;

export const AnnouncementSlide = ({ type, announcement, custom, variants, animate }: any) => (
  <SlideBase variant={type} whileHover={{ height: 350, width: 520 }} custom={custom} variants={variants} animate={animate}>
    <Flex height="100%" alignItems="center">
      <Text ml="10px" mr="10px" color="red" lineHeight="78%" fontSize={[2, 2, 5]} fontWeight={750} maxWidth={360}>
        {truncate(announcement?.toUpperCase(), 150)}
      </Text>
    </Flex>
  </SlideBase>
);
