import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Components
import { Flex, MapAnimation, SEO } from 'components';

// Types
import { ISettings } from 'types/interfaces';

const Welcome: React.FC = () => {
  // // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: '/',
    });
  }, []);

  return (
    <>
      <SEO title="ESNS (Eurosonic Noorderslag)" />
      <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center" backgroundColor="black">
        {settingsData && <MapAnimation {...settingsData} />}
      </Flex>
    </>
  );
};

export default Welcome;
