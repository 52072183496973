import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

// Components
import { Box } from 'components';

// Styled components
import { LocationPin } from './LocationPin';

// Styled components
import { MapContainer, Gradient, RadialGradient, BuildingBackground } from './InterActiveMap.styles';

// Hooks
import { useIsBreakpoint, useWindowSize } from 'hooks';

// Services
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Interfaces
import { ISettings, IStage } from 'types/interfaces';

// Data
import { mapDataCity, mapDataBuilding } from './mapData';

// AppState
import withAppState from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Theme
import theme from 'styles/theme';

const variants = {
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1.2,
      delay: i * 0.3,
    },
  }),
  hidden: {
    opacity: 0,
  },
};

const InterActiveMap: React.FC<any> = ({ locations, dispatch }) => {
  const { isMobile } = useIsBreakpoint();
  const { height } = useWindowSize();

  // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  // Get map data
  const mapData = settingsData?.data?.map === 'building' ? mapDataBuilding : mapDataCity;

  const openStreamModal = (stage: IStage) => {
    dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };

  return (
    <MapContainer bgImage={isMobile ? mapData.imageMobile : mapData.imageDesktop} mobileHeight={height} initial="hidden" animate="visible">
      {settingsData?.data?.map !== 'city' && <BuildingBackground mobileHeight={height} />}
      {settingsData?.data?.map === 'city' && <Gradient mobileHeight={height} />}7
      <RadialGradient mobileHeight={height} />
      {mapData.locations.map(({ top, left, name, slug, bgColor }, i) => {
        const locationData = locations?.find((e: IStage) => e.slug === slug);

        return (
          <motion.div key={i} custom={i} animate="visible" variants={variants}>
            {locationData?.isLive ? (
              <Box onClick={() => openStreamModal(locationData.nowLive.stage)}>
                <LocationPin top={top} left={left} name={name} isLive={locationData?.isLive} />
              </Box>
            ) : (
              <>
                {slug === 'virtual-bar' ? (
                  <>
                    {settingsData?.data.virtualBarUrl !== null && (
                      <a href={settingsData?.data.virtualBarUrl} target="_blank" rel="noreferrer">
                        <LocationPin top={top} left={left} name={name} isLive={locationData?.isLive} />
                      </a>
                    )}
                  </>
                ) : (
                  <NavLink to={`/location/${slug}/?bg=${bgColor}`}>
                    <LocationPin top={top} left={left} name={name} isLive={locationData?.isLive} />
                  </NavLink>
                )}
              </>
            )}
          </motion.div>
        );
      })}
    </MapContainer>
  );
};

export default withAppState(InterActiveMap);
