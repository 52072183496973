import { IStateAction } from '../AppState';

// Styles
import theme from 'styles/theme';

export interface IColorsReducer {
  foreground?: string;
  background?: string;
}
export const colorsReducerInitialState = {
  colors: {
    foreground: theme.colors.yellow,
    background: theme.colors.red,
  },
};

// Exposing the reducer's action types (so we're not passing string literals around).
export const colorsActionTypes = {
  SET_COLORS: 'SET_COLORS',
};

// Basic reducer to set a string literal user mode
export function colorsReducer(state = {}, action: IStateAction): any {
  switch (action.type) {
    case colorsActionTypes.SET_COLORS: {
      return action.payload;
    }
    default:
      return state;
  }
}
