import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const MapContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;

    height: 100%;
    width: 100%;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const VideoPlayer = styled(ReactPlayer)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
