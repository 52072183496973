import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

// Components
import { Flex, PageImage, PageVideo, Text } from 'components';

// Interfaces
import { INewsItem } from 'types/interfaces';

// Local styles
import { ContentContainer, TopSection, TopSectionLeft, TopSectionRight, ImageContainer, StyledText } from './news-detail-content.styles';

interface IArtistDetailContentProps {
  newsItem: INewsItem;
}

const ArtistDetailContent: React.FC<IArtistDetailContentProps> = ({ newsItem }) => {
  const { text, title, video, placeholder, image } = newsItem;

  return (
    <Flex width="100vw" marginTop="160px" justifyContent="flex-start" alignItems="center" flexDirection="column">
      <ContentContainer>
        {title && (
          <h1>
            <Text
              color="foreground"
              fontWeight="normal"
              fontSize={{ xs: '3.1rem', sm: 7, lg: '6.6rem' }}
              lineHeight="106%"
              letterSpacing="-4%"
              mb={{ xs: '26px' }}
              display="inline-block"
              maxWidth="76%"
            >
              <SanitizedHTML allowedTags={['a', 'p']} html={title} />
            </Text>
          </h1>
        )}
        <TopSection>
          <TopSectionLeft>
            {text && (
              <StyledText
                color="foreground"
                fontWeight="normal"
                fontSize={{ xs: '1.8rem', sm: '2.4rem', lg: '2.8rem' }}
                lineHeight="106%"
                letterSpacing="-4%"
                mb={{ xs: '26px' }}
                display="inline-block"
              >
                <SanitizedHTML allowedTags={['a', 'p']} html={text} />
              </StyledText>
            )}
          </TopSectionLeft>

          <TopSectionRight>
            <ImageContainer>
              <PageImage image={image} />
            </ImageContainer>
          </TopSectionRight>
        </TopSection>

        {video && (
          <Flex justifyContent="center" width="100%">
            <PageVideo video={video} image={placeholder} />
          </Flex>
        )}
      </ContentContainer>
    </Flex>
  );
};

export default ArtistDetailContent;
