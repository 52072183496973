import styled from 'styled-components';

import { Text } from 'components';

export const StyledText = styled(Text)`
  ${({ theme }) => `
  a {
    font-weight: ${theme.fontWeights.heavy};
    text-decoration: underline;
  }
  `}
`;
