import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const breakpoints = ['200px', '600px', '800px'];

interface IgenerateSources {
  data: string[] | undefined;
  type?: string;
}

interface IPicture {
  onLoad: (value: React.SetStateAction<boolean>) => void;
  src: string;
  webp: string[] | undefined;
  sizes: string[] | undefined;
}

interface IStyledImg extends HTMLAttributes<HTMLImageElement> {
  onLoad: any;
}

function generateSources({ data, type }: IgenerateSources) {
  if (!data) return null;
  return (
    <>
      {data.map((srcset, i) => (
        <source key={i} srcSet={srcset} media={`(max-width: ${breakpoints[i]})`} type={type} />
      ))}
    </>
  );
}

const StyledPicture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const StyledImg = styled.img<IStyledImg>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const Picture = ({ onLoad, src, webp, sizes }: IPicture) => (
  <StyledPicture>
    {generateSources({ data: webp, type: 'image/webp' })}
    {generateSources({ data: sizes })}
    <StyledImg src={src} onLoad={onLoad} />
  </StyledPicture>
);

export default Picture;
