import React from 'react';

// Slides
import { AnnouncementSlide, NewsSlide, StageSlide, TwitterSlide } from './Slides';

function getSlide(props: any) {
  switch (props.type) {
    case 'nowLiveAtStage':
      return !props.stage.isLive && !props.stage.hasUpcoming ? null : props.stage.isLive ? (
        <StageSlide isLive {...props} />
      ) : (
        props.stage.hasUpcoming && <StageSlide {...props} />
      );

    case 'twitter':
      return <TwitterSlide {...props} />;
    case 'newsArticle':
      return <NewsSlide {...props} />;
    case 'announcement':
      return <AnnouncementSlide {...props} />;
    default:
      return null;
  }
}

export const Slide: React.FC<any> = (props) => getSlide(props);
