import { IStateAction } from '../AppState';

export interface IMainMenuReducer {
  isVisible: boolean;
}

export const mainMenuReducerInitialState = {
  mainMenu: {
    isVisible: true,
  },
};

// Exposing the reducer's action types (so we're not passing string literals around).
export const mainMenuActionTypes = {
  SHOW_MAIN_MENU: 'SHOW_MAIN_MENU',
  HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
};

// Basic reducer to set a string literal user mode
export function mainMenuReducer(state = {}, action: IStateAction): any {
  switch (action.type) {
    case mainMenuActionTypes.SHOW_MAIN_MENU: {
      return {
        ...state,
        isVisible: true,
      };
    }
    case mainMenuActionTypes.HIDE_MAIN_MENU: {
      return {
        ...state,
        isVisible: false,
      };
    }
    default:
      return state;
  }
}
