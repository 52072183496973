import styled from 'styled-components';

// Components
import { Flex } from 'components';

// Styles
import { breakpoint } from 'styles';
import { LayoutContainer } from 'styles/containers';

export const Container = styled.footer`
  position: relative;
  width: 100%;
  margin-bottom: 80px;

  ${breakpoint.md`
    margin-bottom: 0;
  `}
`;

export const InnerContainer = styled(LayoutContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;

  ${breakpoint.md`
      justify-content: space-between;
  `}
`;

export const BoxLeft = styled(Flex)`
  align-items: center;
  width: 45%;

  ${breakpoint.md`
    width: auto;
  `}
`;

export const BoxRight = styled(Flex)`
  width: 55%;
  align-items: center;
  justify-content: flex-end;

  ${breakpoint.md`
    width: auto;
  `}
`;

export const BoxBottom = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${breakpoint.md`
    width: auto;
  `}
`;

export const LinkList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
`;
export const LinkItem = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export const Link = styled.a<{ color: string; hoverColor: string }>`
  text-transform: uppercase;
  align-items: center;
  display: flex;

  transition: color 0.3s ease-in-out;

  ${({ theme, color }) => color && `color: ${theme.colors[color]};`}

  &:hover {
    ${({ theme, hoverColor }) => {
      return `color: ${theme.colors[hoverColor]}`;
    }}
  }
`;

export const LogoImg = styled.img`
  height: 26px;
  width: auto;
`;

export const LogoLink = styled.a`
  display: inline-block;
  margin: 0 8px;
`;
