import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// Styles
import { breakpoint } from 'styles/breakpoint';
import { LayoutContainer } from 'styles/containers';

export const Container = styled(LayoutContainer)`
  position: relative;

  ${breakpoint.sm`
    top: -64px;
  `};
`;

export const ListHeading = styled.h2`
  text-transform: uppercase;

  ${({ theme }) => `
    color: ${theme.colors.yellow};
    font-weight: ${theme.fontWeights.bold};
    font-size: 56px;
  `};
`;

export const List = styled.ul`
  list-style: none;
  margin-bottom: 80px;

  ${breakpoint.md`
    margin-bottom: 100px;
  `}
`;

export const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 36px 0;
`;

export const ListItem = styled.li`
  position: relative;

  &:before {
    content: '';
    width: 100vw;
    height: 1px;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    background-color: white;
    opacity: 0.4;

    ${breakpoint.md`
      width: 100%;
      left: 0;
    `}
  }

  ${({ theme }) => `
    color: ${theme.colors.white};

    &:hover {
      color: ${theme.colors.yellow};
    }
  `};
`;

export const Name = styled.h3`
  width: calc(100% - 114px);
  text-transform: uppercase;

  ${breakpoint.md`
    display: none;
  `}
`;

export const InfoContainer = styled.div`
  width: calc(60% - 20px);

  ${Name} {
    display: none;

    ${breakpoint.md`
      display: block;
    `}
  }
`;

export const Description = styled.p`
  width: 100%;
`;

export const DatePlace = styled.span`
  width: calc(40% - 20px);
  display: flex;
  flex-direction: column;
  text-align: right;

  ${breakpoint.md`
    justify-content: center;
  `}
`;

export const Date = styled.span`
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
`;

export const Place = styled.span`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpoint.md`
    white-space: wrap;
    display: inline;
    overflow: visible;
  `}
`;

export const Country = styled.span`
  display: inline-block;
  width: 100%;

  ${breakpoint.md`
    width: auto;

    &:after {
      content: ' - ';
      white-space: pre;
    }
  `}
`;

export const Genere = styled.span`
  display: block;
  line-height: 130%;

  ${breakpoint.md`
    display: inline-block;
  `}
`;

export const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
