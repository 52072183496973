import React from 'react';

// Components
import { Text } from 'components';

// Utils
import { getBreakedText } from 'utils';

// Local styles
import { Container } from './page-title.styles';

interface IPageTitleProps {
  title: string;
  noTextBreak?: boolean;
}

const PageTitle: React.FC<IPageTitleProps> = ({ title, noTextBreak = false }) => (
  <Container>
    <Text color="foreground" fontSize={{ xs: '140px', sm: '200px', lg: '260px', xl: 13 }} lineHeight="80%" fontWeight="heavy">
      {!noTextBreak ? getBreakedText(title) : title}
    </Text>
  </Container>
);

export default PageTitle;
