import React, { useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import SanitizedHTML from 'react-sanitized-html';

// Components
import { Box, Flex, Text, SliderNavButtons, Quote } from 'components';

// Local styles
import { StyledText } from './stream-slider.styles';

const textProps = {
  color: 'white',
  fontSize: { xs: 1, sm: 3, md: 5 },
  fontWeight: 'bold',
};
interface ISlide {
  handle: 'quote' | 'text';
  data: {
    text: string;
    subtitle: string;
  };
}
interface IStreamSlider {
  slides: ISlide[];
}

const StreamSlider: React.FC<IStreamSlider> = ({ slides }) => {
  const [swiper, setSwiper] = useState({
    activeIndex: undefined,
    slidesLength: undefined,
  });
  const swiperRef = useRef(null);

  const swiperParams = {
    on: {
      init: () => {
        swiperRef && updateSwiperState((swiperRef as any).current.swiper);
      },
      slideChange: () => {
        swiperRef && updateSwiperState((swiperRef as any).current.swiper);
      },
    },
  };

  const navigate = (dir?: 'next ' | 'prev') => {
    if (swiperRef.current && (swiperRef as any)?.current.swiper) {
      if (dir === 'prev') {
        (swiperRef as any)?.current.swiper.slidePrev();
      } else {
        (swiperRef as any)?.current.swiper.slideNext();
      }

      swiperRef && updateSwiperState((swiperRef as any).current.swiper);
    }
  };

  const updateSwiperState = (swiper: any) => {
    setSwiper({
      activeIndex: swiper.activeIndex,
      slidesLength: swiper.slides.length,
    });
  };

  return (
    <div>
      <Swiper ref={swiperRef} {...swiperParams}>
        {slides.map((slide: ISlide, i: number) => (
          <div key={i}>
            {slide.handle === 'quote' ? (
              <Quote quoteText={slide.data.text} captionText={slide.data.subtitle} />
            ) : (
              <StyledText color="white" fontWeight="normal" fontSize={{ xs: 2, sm: 5, lg: 8 }} lineHeight="98%" letterSpacing="-4%">
                <SanitizedHTML allowedTags={['a']} html={slide.data.text} />
              </StyledText>
            )}
          </div>
        ))}
      </Swiper>
      <Flex margin="40px 0 40px" alignItems="center">
        <Box marginRight="20px">
          <Text {...textProps} marginRight="6px">
            {(swiper.activeIndex || 0) + 1}
          </Text>
          <Text {...textProps} marginRight="6px">
            OF
          </Text>
          <Text {...textProps}>{swiper.slidesLength}</Text>
        </Box>

        <SliderNavButtons
          color="white"
          onNextClick={() => navigate()}
          onPrevClick={() => navigate('prev')}
          isNextInActive={swiper.activeIndex === (swiper.slidesLength || 0) - 1}
          isPrevInActive={swiper.activeIndex === 0}
        />
      </Flex>
    </div>
  );
};

export default StreamSlider;
