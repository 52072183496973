// Interfaces
import { colorsReducerInitialState, IColorsReducer } from './reducers/colorsReducer';
import { streamModalReducerInitialState, IStreamModalReducer } from './reducers/streamModalReducer';
import { mainMenuReducerInitialState, IMainMenuReducer } from './reducers/mainMenuReducer';

export interface IStateAction {
  type: string;
  payload: IColorsReducer | IStreamModalReducer;
}

// All the state for the application.
export interface IAppState {
  colors: IColorsReducer;
  streamModal: IStreamModalReducer;
  mainMenu: IMainMenuReducer;
}

// The default state for the application.
export const initialState: IAppState = {
  ...colorsReducerInitialState,
  ...streamModalReducerInitialState,
  ...mainMenuReducerInitialState,
};
