import styled from 'styled-components';
import { variant } from 'styled-system';

import { ResponsiveValue } from 'styled-system';

import { Box } from 'components';

type CSS = React.CSSProperties;
type Variant = 'center';

export const Flex = styled(Box)<{ variant?: Variant; space?: ResponsiveValue<CSS['margin']> }>`
  display: flex;
  ${(p) =>
    variant({
      variants: {
        center: {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    })(p)}
`;

Flex.displayName = 'Flex';

// import React, { ReactNode } from 'react';
// import { FlexComponent, FlexProps } from './flex.styles';

// interface Props extends FlexProps {
//   children?: ReactNode;
// }

// export const Flex = (props: Props) => <FlexComponent {...props} />;
