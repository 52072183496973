import React, { useEffect } from 'react';

// Contexts
import { ContextProviders } from 'contexts';

// Router
import { BrowserRouter as Router } from 'react-router-dom';
import { RenderRoutes } from 'router';

// Fonts
import 'styles/fonts/stylesheet.css';

import 'components/FeedSlider/swiper.css';

// Components
import { FeedModal, Header } from 'components';

// Local styles
import { Main } from './app.styles';

// Utils
import { detectTouchDevice } from 'utils';

import { loadExternalScripts } from 'utils';

// Routes
import routes from 'router/Routes';

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'production') {
      loadExternalScripts();
    }

    detectTouchDevice();
  }, []);

  return (
    <ContextProviders>
      <Router>
        <Header />
        <Main>
          <RenderRoutes routes={routes} />
        </Main>

        <FeedModal />
      </Router>
    </ContextProviders>
  );
};

export default App;
