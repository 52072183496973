import styled from 'styled-components';
import { useIsBreakpoint } from 'hooks';
import { NavLink } from 'react-router-dom';

// AppState
import withAppState from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Components
import { Flex, Box, Text, Img } from 'components';
import { SlideBase } from '../../SlideBase';

// Lib
import { truncate } from 'lib';

// Interfaces
import { IStage } from 'types/interfaces';

// Theme
import theme from 'styles/theme';

const StageSlide: React.FC<any> = ({ type, stage, custom, variants, animate, isLive, dispatch }) => {
  const { isMobile } = useIsBreakpoint();

  const disableSnippetForMobile = false;

  const upcomingImageSrc = {
    placeholder: stage?.upcoming?.artists[0].image.formats.mobile,
    src: stage?.upcoming?.artists[0].image.formats.desktop,
    sizes: [
      stage?.upcoming?.artists[0].image.formats.mobile,
      stage?.upcoming?.artists[0].image.formats.tablet,
      stage?.upcoming?.artists[0].image.formats.desktop,
    ],
    webp: [
      stage?.upcoming?.artists[0].image.formats['mobile@webp'],
      stage?.upcoming?.artists[0].image.formats['tablet@webp'],
      stage?.upcoming?.artists[0].image.formats['desktop@webp'],
    ],
  };

  const nowLiveImg = {
    placeholder: stage?.nowLive?.artists[0].image.formats.mobile,
    src: stage?.nowLive?.artists[0].image.formats.desktop,
    sizes: [
      stage?.nowLive?.artists[0].image.formats.mobile,
      stage?.nowLive?.artists[0].image.formats.tablet,
      stage?.nowLive?.artists[0].image.formats.desktop,
    ],
    webp: [
      stage?.nowLive?.artists[0].image.formats['mobile@webp'],
      stage?.nowLive?.artists[0].image.formats['tablet@webp'],
      stage?.nowLive?.artists[0].image.formats['desktop@webp'],
    ],
  };

  const openStreamModal = (stage: IStage) => {
    dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };
  return (
    <>
      {isLive ? (
        <SlideBase
          variant={type}
          stage={stage}
          whileHover={{ height: 350, width: 520 }}
          custom={custom}
          variants={variants}
          animate={animate}
          onClick={() => openStreamModal(stage.nowLive.stage)}
        >
          <Flex height="100%" flexDirection="column" justifyContent="flex-end">
            <Text
              ml="10px"
              mb={['10px', '10px', '20px', '20px']}
              lineHeight={[1, 1, '78%', '78%']}
              fontSize={[2, 2, 5]}
              fontWeight={750}
              maxWidth={360}
              zIndex={100}
            >
              {truncate(stage?.nowLive?.artists[0].title.replace(/(<([^>]+)>)/gi, '').toUpperCase(), 40)}
            </Text>
            <Text
              ml="10px"
              mb="10px"
              pr={['35px', '35px', '0px', '0px']}
              lineHeight={[1, 1, '78%', '78%']}
              fontSize={0}
              fontWeight={700}
              maxWidth={360}
              zIndex={100}
            >
              {truncate(stage?.nowLive?.artists[0].subTitle.toUpperCase(), 45)}
            </Text>
            <MediaContainer position="absolute" top={0} left={0} width="100%" height="100%" zIndex={0}>
              {stage.isLive &&
                (stage.nowLive.snippetVideo !== null ? (
                  <Video autoPlay playsInline loop muted src={stage.nowLive.snippetVideo} />
                ) : (
                  <Img name="img" {...nowLiveImg} />
                ))}
            </MediaContainer>
          </Flex>
        </SlideBase>
      ) : (
        <NavLink to={`/artist/${stage?.upcoming?.artists[0].slug}/${stage?.upcoming?.artists[0].id}`}>
          <SlideBase
            variant={type}
            stage={stage}
            whileHover={{ height: 350, width: 520 }}
            custom={custom}
            variants={variants}
            animate={animate}
          >
            <Flex height="100%" flexDirection="column" justifyContent="flex-end">
              <Text
                ml="10px"
                mb={['10px', '10px', '20px', '20px']}
                lineHeight={[1, 1, '78%', '78%']}
                fontSize={[2, 2, 5]}
                fontWeight={750}
                maxWidth={360}
                zIndex={100}
              >
                {truncate(stage?.upcoming.artists[0].title.replace(/(<([^>]+)>)/gi, '').toUpperCase(), 40)}
              </Text>
              <Text ml="10px" mb="10px" pr={['35px', '35px', '0px', '0px']} fontSize={0} fontWeight={700} maxWidth={360} zIndex={100}>
                {truncate(stage?.upcoming.artists[0].subTitle.toUpperCase(), 45)}
              </Text>
              <MediaContainer position="absolute" top={0} left={0} width="100%" height="100%" zIndex={0}>
                {stage.upcoming?.snippetVideo !== null ? (
                  <Video autoPlay playsInline loop muted src={stage.upcoming?.snippetVideo} />
                ) : (
                  <>
                    <Img name="img" {...upcomingImageSrc} />
                  </>
                )}
              </MediaContainer>
            </Flex>
          </SlideBase>
        </NavLink>
      )}
    </>
  );
};

export default withAppState(StageSlide);

const Video = styled.video`
  height: 100%;
  object-fit: cover;
`;

const MediaContainer = styled(Box)`
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: -40px;
    left: -40px;
    width: calc(100% + 80px);
    height: calc(100% + 80px);
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 1;
    opacity: 0.25;
  }
`;
