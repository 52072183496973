import styled from 'styled-components';

// Styles
import { breakpoint } from 'styles/breakpoint';

// Styles
import { LayoutContainer } from 'styles/containers';

export const Container = styled(LayoutContainer)`
  position: relative;
  z-index: 5;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 120px;

  ${breakpoint.md`
    margin-top: 180px;
    margin-bottom: 0px;
  `};
`;
