import React from 'react';
import { AnimatePresence } from 'framer-motion';

// External packages
import { Route, Switch, useLocation } from 'react-router-dom';

import { RouteObject } from './Routes';

interface IRenderRoutes {
  routes: RouteObject[];
}

export const RenderRoutes: React.FC<IRenderRoutes> = ({ routes }) => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Switch key="1" location={location}>
        {routes?.map((route: RouteObject, i: number) => (
          <Route key={`${i}-${route.name}`} path={route.path} exact={route.exact} status={route.status}>
            {route.pageComponent}
          </Route>
        ))}

        <Route path="*">Not found</Route>
      </Switch>
    </AnimatePresence>
  );
};
