import { NavLink } from 'react-router-dom';

// Components
import { Flex, Box, Badge, Text } from 'components';

// Styled components
import { SliderWrapper, Image, ArtistTitle, ImageContainer } from './slide.styles';

// Interfaces
import { ITimeslot } from 'types/interfaces';

// AppState
import withAppState from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Interfaces
import { IStage } from 'types/interfaces';

// Theme
import theme from 'styles/theme';

export const SlideContent: React.FC<ITimeslot> = ({ title, startDateTime, artists, isLive }) => {
  const imageSrc = {
    placeholder: artists[0]?.image?.formats['stage-mobile'],
    src: artists[0]?.image?.formats['stage-desktop'],
    sizes: [
      artists[0]?.image?.formats['stage-mobile'],
      artists[0]?.image?.formats['stage-tablet'],
      artists[0]?.image?.formats['stage-desktop'],
    ],
    webp: [
      artists[0]?.image?.formats['stage-mobile_webp'],
      artists[0]?.image?.formats['stage-tablet_webp'],
      artists[0]?.image?.formats['stage-desktop_webp'],
    ],
  };

  return (
    <SliderWrapper>
      <Box position="absolute" left={['10px', '10px', 'unset', 'unset']} right={['unset', 'unset', '10px', '10px']} top="8px" zIndex={10}>
        <Badge isLive={isLive} startDateTime={startDateTime} title={title} />
      </Box>
      <ImageContainer position="absolute" top={0} left={0} minWidth="100%" minHeight="100%" zIndex={-10}>
        <Image name="img" {...imageSrc} />
      </ImageContainer>
      <Flex flexDirection="column" justifyContent="flex-end" mb={['20px', '20px', '40px', '40px']} maxWidth="75%">
        <ArtistTitle
          textAlign={['left', 'left', 'center', 'center']}
          fontSize={[2, 2, 2, 7]}
          fontWeight={750}
          color="white"
          lineHeight={[1, 1, 1, '37.44px']}
          mb="20px"
          zIndex={1000}
        >
          {artists[0].title.toUpperCase()}
        </ArtistTitle>
        {artists[0].subTitle && (
          <Text textAlign={['left', 'left', 'center', 'center']} fontSize={0} fontWeight={750} color="white">
            {artists[0].subTitle.toUpperCase()}
          </Text>
        )}
      </Flex>
    </SliderWrapper>
  );
};

const Slide = (props: any) => {
  const openStreamModal = (stage: IStage) => {
    props.dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };

  return props.isLive ? (
    <Box onClick={() => openStreamModal(props.stage)}>
      <SlideContent {...props} />
    </Box>
  ) : (
    <NavLink to={`/artist/${props.artists[0].slug}/${props.artists[0].id}`}>
      <SlideContent {...props} />
    </NavLink>
  );
};

export default withAppState(Slide);
