import styled from 'styled-components';

// Components
import { Img } from 'components';

// Styles
import { breakpoint } from 'styles';

export const ImageInnerContainer = styled.div`
  display: flex;
  width: 278px;
  height: 363px;

  ${({ theme }) => theme && `background-color: ${theme.colors.foreground};`};

  ${breakpoint.lg`
    width: 387px;
    height: 506px;
  `};

  ${breakpoint.xl`
    width: 541px;
    height: 707px;
  `};
`;

export const StyledImg = styled(Img)`
  position: absolute !important;
  width: 194px;
  height: 278px;

  ${breakpoint.lg`
    width: 271px;
    height: 387px;
  `};

  ${breakpoint.xl`
    width: 379px;
    height: 542px;
  `};

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
`;

export const StyledImgContainer = styled.div`
  margin: 0 auto;
  display: flex;
  width: 194px;
  height: 278px;

  ${breakpoint.lg`
    width: 271px;
    height: 387px;
  `};

  ${breakpoint.xl`
    width: 379px;
    height: 542px;
  `};
`;

export const StyledImgOuter = styled.div`
  width: 278px;
  height: 363px;

  ${breakpoint.lg`
    width: 387px;
    height: 506px;
  `};

  ${breakpoint.xl`
    width: 541px;
    height: 707px;
  `};

  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
`;
