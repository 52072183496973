import styled from 'styled-components';

// Styles
import { breakpoint } from 'styles/breakpoint';

export const Container = styled.figure`
  text-align: center;
  width: 100%;
`;

export const QuoteText = styled.blockquote`
  > span {
    display: block;
    text-align: center;
  }

  margin-bottom: 6px;

  ${breakpoint.sm`
  margin-bottom: 32px;
  `};
`;

export const CaptionText = styled.figcaption`
  > span {
    display: block;
    text-align: center;
  }
`;
