import React, { useState, useRef } from 'react';
import styled from 'styled-components';

// Components
import Picture from './components/Picture';
import useIntersectionObserver from './components/useIntersectionObserver';

const StyledImg = styled.img<{ isLoaded: boolean }>`
  position: relative;
  width: 100%;
  z-index: 1;
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  transition: opacity 0.3s;
`;

interface IImg {
  src: string;
  placeholder?: string;
  sizes?: string[];
  webp?: string[];
  name: string;
}

export const Img = ({ placeholder, src, sizes, webp, name, ...props }: IImg) => {
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const containerRef: any = useRef();
  useIntersectionObserver({
    ref: containerRef,
    onViewportEnter: () => {
      setIsInView(true);
    },
  });

  return (
    <div
      className={name}
      ref={containerRef}
      style={{
        position: `relative`,
      }}
      {...props}
    >
      {placeholder && <StyledImg src={placeholder} isLoaded={isLoaded} />}

      {isInView && <Picture onLoad={() => setIsLoaded(true)} src={src} sizes={sizes} webp={webp} />}
    </div>
  );
};
