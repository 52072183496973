import styled from 'styled-components';

// Utils
import { breakpoint } from 'styles/breakpoint';

const containerTopPadding = 6;
const containerLeftPadding = 7;

export const Container = styled.nav`
  pointer-events: all;
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    pointer-events: none;
    z-index: -1;
    display: block;
    position: absolute;
    border-radius: 30px;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    ${({ theme }) => `
      border: 1px solid ${theme.colors.foreground};
      background-color: ${theme.colors.background};
      width: calc(100% + ${containerLeftPadding * 2}px);
      height: calc(100% + ${containerTopPadding * 2}px);
      top: ${containerTopPadding * -1}px;
      left: ${containerLeftPadding * -1}px;
    `}
  }
`;

export const SelectedMenuBg = styled.span<{
  width: string;
  height: string;
  transform: string;
}>`
  z-index: -1;
  position: absolute;
  border-radius: 50px;
  display: block;
  transition: background-color 0.15s ease-in-out, transform 0.2s ease-in-out;

  ${({ theme, width, height, transform }) => `
      background-color: ${theme.colors.foreground};
      width: ${width};
      height: ${height};
      transform: ${transform};
  `}
`;

export const MenuList = styled.ul`
  list-style: none;
`;

export const MenuItem = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 4px;

    ${breakpoint.md`
      margin-right: 16px;
    `}
  }
`;

export const MenuButton = styled.div<{ selected: boolean }>`
  padding: 9px 13px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50px;
  transition: all 0.2s;
  text-transform: uppercase;
  transition: all ease-in-out;
  outline: none;

  ${breakpoint.md`
    padding: 10px 18px;
  `}

  ${({ selected, theme }) => `color: ${theme.colors[selected ? 'background' : 'foreground']}`};
`;
