import styled from 'styled-components';
import { variant } from 'styled-system';

// Styles
import { breakpoint } from 'styles/breakpoint';

const badgeStyles = (v: any) =>
  variant({
    variants: {
      isLive: {
        bg: 'yellow',
        color: 'red',
      },
      hasUpcoming: {
        bg: 'white',
        color: 'red',
      },
    },
  })(v);

export const BadgeBase = styled.span<any>`
  padding: 3px 8px;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  text-align: center;
  z-index: 100;

  ${breakpoint.md`
    font-weight: 800;
    padding: 5px 10px;
  `}

  ${badgeStyles}
`;
