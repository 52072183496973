import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Components
import { Flex, PageTitle, SEO, ShuffleArtists, SubTitle } from 'components';

// Interfaces
import { ITimeslot } from 'types/interfaces';

// Animation
import { PageAnimationContainer, SubtitleAnimationContainer } from 'styles/animations';

// Local styles
import { TitleContainer } from './discover.styles';

const pageTitle = 'Discover artists';

interface IAllArtistData {
  [key: string]: ITimeslot;
}

const Discover: React.FC = () => {
  const [currentTimeSlot, setcurrentTimeSlot] = useState<ITimeslot>();
  const [allArtistDataState, setallArtistDataState] = useState<IAllArtistData>();

  const { data: someArtistData } = useQuery<IAllArtistData, string>(`some-artists`, () =>
    fetchData(`${apiEndpoints.getAllArtists}?limit=30&images=desktop`)
  );

  const { data: allArtistData } = useQuery<IAllArtistData, string>(`all-artists`, () =>
    fetchData(`${apiEndpoints.getAllArtists}?images=desktop`)
  );

  useEffect(() => {
    if (!allArtistData) {
      setallArtistDataState(someArtistData);
    } else {
      setallArtistDataState(allArtistData);
    }
  }, [someArtistData, allArtistData]);

  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: '/discover',
    });
  }, []);

  const currentArtist = currentTimeSlot?.artists[0];

  return (
    <>
      <SEO title="Discover Artists | Eurosonic Noorderslag (ESNS)" />
      <PageAnimationContainer>
        <Flex justifyContent="center" height="100vh" alignItems="flex-start" overflow="hidden">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={{ xs: '100px', md: '160px', lg: '200px' }}
            textAlign="center"
          >
            <AnimatePresence key={currentArtist?.id} initial>
              <SubtitleAnimationContainer>
                <SubTitle color="foreground" title={currentArtist?.subTitle} />
              </SubtitleAnimationContainer>
            </AnimatePresence>

            <Flex flexWrap="wrap">
              <TitleContainer>
                <PageTitle title={pageTitle} />
              </TitleContainer>

              {allArtistDataState && (
                <ShuffleArtists
                  allArtistData={allArtistDataState}
                  onShuffleComplete={(timeslot: ITimeslot) => setcurrentTimeSlot(timeslot)}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </PageAnimationContainer>
    </>
  );
};

export default Discover;
