import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// Styles
import { breakpoint } from 'styles';

const imageSizes = css`
  width: 265px;
  height: 265px;

  ${breakpoint.md`
      width: 400px;
      height: 400px;
    `}
`;

export const ArtistLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  text-transform: uppercase;
  ${imageSizes};

  > span {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 0.4;
    }

    span {
      opacity: 1;
    }
  }

  &:before {
    transition: opacity 0.4s ease-in-out;
    content: '';
    opacity: 0;
    background-color: black;
    transform: rotate(-2.7deg);
    transform-origin: center center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ShuffleInnerContainer = styled.div`
  position: absolute;
  transition: transform 0.2s ease-in-out;
`;

export const ShuffleContainer = styled.div`
  position: relative;
  ${imageSizes};
`;

export const ShuffleButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;

  ${breakpoint.md`
    right: -85px;
  `}
`;
