import { GlobalStore } from './AppStateProvider';
import { IAppState } from './AppState';

export interface IWithAppState {
  state: IAppState;
  dispatch: ({ type }: { type: string; payload?: any }) => void;
}

// A higher order component to inject the state and dispatcher
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withAppState = (Component: any) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  function WrapperComponent(props: any) {
    return (
      <GlobalStore.Consumer>
        {(context) => {
          return <Component {...props} state={context.state} dispatch={context.dispatch} />;
        }}
      </GlobalStore.Consumer>
    );
  };

export default withAppState;
