import styled from 'styled-components';

// Components
import { Text } from 'components';

// Styles
import { breakpoint } from 'styles';
import { LayoutContainer } from 'styles/containers';

export const ContentContainer = styled(LayoutContainer)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 80px;
  will-change: contents;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  ${breakpoint.md`
    flex-direction: row;
    margin-bottom: 140px;
  `}

  ${breakpoint.xl`
    margin-bottom: 180px;
  `}
`;

export const TopSectionLeft = styled.div`
  ${breakpoint.md`
    width: 50%;
  `}
`;

export const TopSectionRight = styled.div`
  ${breakpoint.md`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50%;
  `}
`;

export const StyledText = styled(Text)`
  p {
    margin-bottom: 26px;

    ${breakpoint.md`
      margin-bottom: 36px;
    `}
  }

  a {
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: black;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  right: -20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  ${breakpoint.md`
    margin-top: 200px;
    width: 100%;
    right: -70px;
  `}
`;
