import React from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AnimatePresence } from 'framer-motion';

// Data fetching
import { fetchData } from 'services/fetchService';
import { apiEndpoints } from 'static/apiEndpoints';

// Interfaces
import { ISettings, ITimetableToday } from 'types/interfaces';

//Components
import { Button, Flex } from 'components';

// Local styles
import { Container } from './programme-header.styles';

// Styles
import { SubtitleAnimationContainer } from 'styles/animations';
interface IProgrammeHeaderProps {
  links?: string[];
}

const ProgrammeHeader: React.FC<IProgrammeHeaderProps> = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const isTimeTableActive = pathname === '/programme';
  const isArtistListActive = pathname === '/programme/artists';

  // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  const { data: timetableData } = useQuery<ITimetableToday, string>(`timetable-today`, () => fetchData(apiEndpoints.getTimetableToday));

  return (
    <Container>
      <AnimatePresence key={timetableData?.day}>
        <SubtitleAnimationContainer>
          <Flex justifyContent={{ xs: 'center', md: 'flex-end' }} width="100%" minHeight="44px">
            {settingsData?.data.state !== 'after' && (
              <>
                <NavLink to={url}>
                  <Button
                    mr="20px"
                    variant={isTimeTableActive ? undefined : 'outline'}
                    foregroundColor={isTimeTableActive ? 'background' : 'foreground'}
                    backgroundColor={isTimeTableActive ? 'foreground' : 'background'}
                  >
                    Timetable {timetableData?.day?.substring(0, 3)}
                  </Button>
                </NavLink>
                <NavLink to={`${url}/artists`}>
                  <Button
                    variant={isArtistListActive ? undefined : 'outline'}
                    foregroundColor={isArtistListActive ? 'background' : 'foreground'}
                    backgroundColor={isArtistListActive ? 'foreground' : 'background'}
                  >
                    ARTIST A-Z
                  </Button>
                </NavLink>
              </>
            )}
          </Flex>
        </SubtitleAnimationContainer>
      </AnimatePresence>
    </Container>
  );
};

export default ProgrammeHeader;
