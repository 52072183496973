import { NavLink } from 'react-router-dom';

// Components
import { Flex, Text } from 'components';
import { SlideBase } from '../../SlideBase';

// Lib
import { truncate } from 'lib';

export const NewsSlide = ({ type, item, custom, variants, animate }: any) => (
  <NavLink to={`/news/${item.slug}/${item.id}`}>
    <SlideBase variant={type} whileHover={{ height: 350, width: 520 }} custom={custom} variants={variants} animate={animate}>
      <Flex flexDirection="column" justifyContent="flex-end">
        <Text
          ml="10px"
          mb={['30px', '30px', '60px', '60px']}
          mr="10px"
          color="red"
          lineHeight="78%"
          fontSize={[2, 2, 5]}
          fontWeight={750}
          maxWidth={360}
        >
          {truncate(item?.title.toUpperCase(), 45)}
        </Text>
      </Flex>
    </SlideBase>
  </NavLink>
);
