export const truncate = (string: string, length = 200): string => {
  if (!string) return '';
  const ending = '...';
  if (string.length > length) {
    // Remove last space from string
    const rtrim = (string: string) => string.replace(/((\s*\S+)*)\s*/, '$1');
    return rtrim(string.substring(0, length)) + ending;
  } else {
    return string;
  }
};
