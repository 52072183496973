import styled from 'styled-components';
import { system, ISystem } from 'lib';

// Utils
import { breakpoint } from 'styles/breakpoint';
import { Button } from 'components';

export const Container = styled.header`
  z-index: 100;
  width: 100%;
  pointer-events: none;
  padding: 0 20px;

  ${breakpoint.md`
    padding: 0 40px;
    top: 40px;
    position: fixed;
  `}
`;

export const LogoContainer = styled.div<{ hide?: boolean }>`
  width: 100%;
  display: flex;
  pointer-events: all;
  justify-content: space-between;
  position: fixed;
  margin-top: 28px;
  padding-left: 20px;
  padding-right: 20px;
  left: 0;
  top: 0;
  z-index: 100;
  transition: transform 0.4s ease-in-out;

  ${({ hide }) => hide && `transform: translateY(-100px);`}

  svg {
    width: 102px;

    ${breakpoint.md`
      width: 142px;
    `}
  }

  ${breakpoint.md`
    position: static;
    margin: 0;
  `}
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const NpoWrapper = styled.div<ISystem>`
  display: flex;
  align-items: flex-end;
  margin-left: 7px;
  ${breakpoint.md`
    margin-left: 0px;
  `}
  span {
    color: #fed46e;
    font-size: 8px;
    margin-right: 5px;
    text-transform: uppercase;
    ${breakpoint.md`
      font-size: 12px;
      margin-right: 7px;
    `}
  }
  ${({ color, theme }) =>
    `
      span {
        color: ${theme.colors[color]};
      }
    `};
  ${system};

  a {
    img {
      height: 19px;
      width: auto;
      ${breakpoint.md`
        height: 27px;
      `}
    }
  }
`;

export const ExternalLink = styled.a<ISystem>`
  display: inline-block;
  text-transform: uppercase;
  height: 17px;
  padding-bottom: 4px;
  pointer-events: all;
  position: relative;
  right: 0;
  top: 0;
  z-index: 100;
  margin-top: 8px;

  ${({ color, theme }) =>
    `
      font-size: ${theme.fontSizes[0.5]};
      line-height: 1;
      font-weight: bold;
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
        left: 0;
        background-color: ${theme.colors[color]};
      }
    `};

  ${system};

  ${breakpoint.md`
    position: relative;
    margin: 8px 0 0 0;
  `}
`;

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  bottom: 28px;
  left: 0;
  z-index: 100;

  ${breakpoint.md`
    position: absolute;
    top: 0;
    bottom: auto;
  `}
`;

export const CloseButton = styled(Button)`
  pointer-events: all;
  position: absolute;
  right: 0;
  top: -24px;
  z-index: 100;
  margin-top: 14px;
  margin-right: 20px;

  ${breakpoint.md`
    top: 0;
    position: relative;
    margin: 0;
  `}
`;
