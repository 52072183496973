import React from 'react';

// Pages
import { Welcome, Feed, Discover, Location, ArtistDetail, NewsDetail, Programme, NotFound, TimeTable, ArtistAZ } from 'pages';

// Styles
import { PageAnimationContainer } from 'styles/animations';

export interface RouteObject {
  name: string;
  path: string | string[];
  status?: number;
  exact: boolean;
  pageComponent: React.ReactElement;
  themeColor?: string;
}

export const routes: RouteObject[] = [
  {
    name: 'welcome',
    path: '/',
    exact: true,
    pageComponent: <Welcome />,
  },
  {
    name: 'feed',
    path: '/feed',
    exact: true,
    pageComponent: <Feed />,
  },
  {
    name: 'discover',
    path: '/discover',
    exact: false,
    pageComponent: <Discover />,
  },
  {
    name: 'artist',
    path: '/artist/:slug/:id',
    exact: true,
    pageComponent: <ArtistDetail />,
  },
  {
    name: 'location',
    path: '/location/:slug',
    exact: false,
    pageComponent: <Location />,
  },
  {
    name: 'news',
    path: '/news/:slug/:id',
    exact: false,
    pageComponent: <NewsDetail />,
  },
  {
    name: 'programme',
    path: '/programme',
    exact: false,
    pageComponent: <Programme />,
  },
  {
    name: 'notFound',
    path: '/*',
    exact: false,
    status: 404,
    pageComponent: <NotFound />,
  },
];

export const programmeRoutes: RouteObject[] = [
  {
    name: 'programme',
    path: '/programme',
    exact: true,
    pageComponent: (
      <PageAnimationContainer key="programme">
        <TimeTable />
      </PageAnimationContainer>
    ),
  },
  {
    name: 'programme-artists',
    path: '/programme/artists',
    exact: false,
    pageComponent: (
      <PageAnimationContainer key="programme-artists">
        <ArtistAZ />
      </PageAnimationContainer>
    ),
  },
];

export default routes;
