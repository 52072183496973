import { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useIsBreakpoint = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } => {
  const theme = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < Number(theme.breakpoints.md?.replace(/\D/g, '')));
    checkIfMobile();

    const checkIfTablet = () =>
      setIsTablet(
        window.innerWidth > Number(theme.breakpoints.md?.replace(/\D/g, '')) &&
          window.innerWidth < Number(theme.breakpoints.lg?.replace(/\D/g, ''))
      );
    checkIfTablet();

    const checkIfDesktop = () => setIsDesktop(window.innerWidth > Number(theme.breakpoints.lg?.replace(/\D/g, '')));
    checkIfDesktop();

    window.addEventListener('resize', checkIfMobile);
    window.addEventListener('resize', checkIfTablet);
    window.addEventListener('resize', checkIfDesktop);

    () => window.removeEventListener('resize', checkIfMobile);
    () => window.removeEventListener('resize', checkIfTablet);
    () => window.removeEventListener('resize', checkIfDesktop);
  }, [theme.breakpoints.md, theme.breakpoints.lg]);

  return { isMobile, isTablet, isDesktop };
};

export default useIsBreakpoint;
