import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AnimatePresence, useViewportScroll, useTransform, motion } from 'framer-motion';

// Theme
import theme from 'styles/theme';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Hooks
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Components
import { Box, Flex, ShuffleArtists, Spinner, PageTitle, SubTitle, SEO } from 'components';
import { ArtistDetailContent } from './subComponents/ArtistDetailContent';

// Interfaces
import { IArtist, ITimeslot } from 'types/interfaces';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { colorsActionTypes } from 'AppState/reducers/colorsReducer';

// Styles
import { LeftToRightAnimationContainer, PageAnimationContainer, SubtitleAnimationContainer } from 'styles/animations';
import {
  InitialBG,
  InitialImg,
  ShuffleArtistsContainer,
  ShuffleContainer,
  TitleContainer,
  ImgInnerContainer,
  ImgContainer,
} from './artist-detail.styles';

// Libs & utils
import { truncate } from 'lib';
import { stripTags } from 'utils';

export interface IArtistData {
  artist: IArtist;
  timeslots: ITimeslot[];
}

interface IAllArtistData {
  [key: string]: ITimeslot;
}

const Artist: React.FC<IWithAppState> = ({ dispatch }) => {
  const { slug } = useParams<{ id: string; slug: string }>();
  const [artistState, setArtistState] = useState<IArtistData>();
  const { scrollY } = useViewportScroll();
  const opacity = useTransform(scrollY, [500, 80], [0.14, 1]);

  const [allArtistDataState, setallArtistDataState] = useState<IAllArtistData>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bgColor: string | null = searchParams.get('bg');

  const history = useHistory();

  const { data: artistData, status: artistDataStatus } = useQuery<IArtistData, string>(`artist/${slug}`, () =>
    fetchData(apiEndpoints.getArtist + slug + '.json')
  );

  const { data: someArtistData } = useQuery<IAllArtistData, string>(`some-artists`, () =>
    fetchData(`${apiEndpoints.getAllArtists}?limit=30&images=desktop`)
  );

  const { data: allArtistData } = useQuery<IAllArtistData, string>(`all-artists`, () =>
    fetchData(`${apiEndpoints.getAllArtists}?images=desktop`)
  );

  useEffect(() => {
    if (!allArtistData) {
      setallArtistDataState(someArtistData);
    } else {
      setallArtistDataState(allArtistData);
    }
  }, [someArtistData, allArtistData]);

  const updateBgColor = (bgColor: string) => {
    dispatch({
      type: colorsActionTypes.SET_COLORS,
      payload: {
        foreground: theme.colors.yellow,
        background: `#${bgColor}`,
      },
    });
  };

  const updateArtist = (timeslot: ITimeslot) => {
    const artist = timeslot.artists[0];

    setArtistState({
      timeslots: [timeslot],
      artist,
    });

    // Scroll window to top on every artist update
    window.scrollTo(0, 0);

    const bgColor = timeslot.stage.secondaryColor.slice(1);

    updateBgColor(bgColor);

    // Update page view for tracking
    (window as any).NPO?.view({
      pagina: `/artist/${artist.slug}/${artist.id}?bg=${bgColor}`,
    });

    // Update Browser url whitout a page refresh
    window.history.replaceState(null, '', `/artist/${artist.slug}/${artist.id}?bg=${bgColor}`);
  };

  useEffect(() => {
    if (bgColor) {
      updateBgColor(bgColor);
    }
  }, [bgColor]);

  useEffect(() => {
    if (artistData) {
      // Init page view for tracking
      (window as any).NPO?.view({
        pagina: `/artist/${artistData.artist.slug}/${artistData.artist.id}?bg=${bgColor}`,
      });

      setArtistState(artistData);
    }
  }, [artistData]);

  // Redirect to Error page when the status is error
  useEffect(() => {
    if (artistDataStatus === 'error') {
      history.push('/404');
    }
  }, [artistDataStatus]);

  if (!artistState) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  const { artist, timeslots } = artistState;

  const initialArtist = artistData && artistData?.artist;

  const imageSrc = initialArtist
    ? {
        placeholder: initialArtist?.image?.formats.mobile,
        src: initialArtist?.image?.formats.desktop,
        sizes: [initialArtist?.image?.formats.mobile, initialArtist?.image?.formats.tablet, initialArtist?.image?.formats.desktop],
        webp: [
          initialArtist?.image?.formats['mobile_webp'],
          initialArtist?.image?.formats['tablet_webp'],
          initialArtist?.image?.formats['desktop_webp'],
        ],
      }
    : {
        placeholder: '',
        src: '',
        sizes: ['', '', ''],
        webp: ['', '', ''],
      };

  return (
    <>
      {artistState && (
        <>
          <SEO title={`${artist.title} | Eurosonic Noorderslag (ESNS)`} description={truncate(stripTags(artist.intro), 140)} />
          <motion.div style={{ opacity: opacity, willChange: 'opacity' }}>
            <TitleContainer>
              <AnimatePresence key={artist.id}>
                <PageAnimationContainer>
                  <PageTitle title={artist.title} />
                </PageAnimationContainer>
              </AnimatePresence>
            </TitleContainer>

            <ShuffleContainer>
              <AnimatePresence key={artist.id}>
                <SubtitleAnimationContainer>
                  <SubTitle color="foreground" title={artist?.subTitle} />
                </SubtitleAnimationContainer>
              </AnimatePresence>
              <ShuffleArtistsContainer>
                <ImgContainer>
                  <ImgInnerContainer>
                    <AnimatePresence initial>
                      <LeftToRightAnimationContainer>
                        <InitialBG />
                        <InitialImg name="img" {...imageSrc} />
                      </LeftToRightAnimationContainer>
                    </AnimatePresence>
                  </ImgInnerContainer>

                  {allArtistDataState && (
                    <ShuffleArtists
                      allArtistData={allArtistDataState}
                      onShuffleComplete={updateArtist}
                      activeTimeslotId={'123'}
                      hasClickThrough={false}
                    />
                  )}
                </ImgContainer>
              </ShuffleArtistsContainer>
            </ShuffleContainer>
          </motion.div>
        </>
      )}
      <Box height={{ xs: '600px', md: '700px', lg: '740px' }} />

      <AnimatePresence key={artist.id}>
        <PageAnimationContainer>
          <ArtistDetailContent artist={artist} timeslots={timeslots} />;
        </PageAnimationContainer>
      </AnimatePresence>
    </>
  );
};

export default withAppState(Artist);
