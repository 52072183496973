import React from 'react';
import { IImage } from 'types/interfaces';

import { ImageInnerContainer, StyledImgContainer, StyledImg, StyledImgOuter } from './page-image.styles';

interface IPageImageProps {
  image: IImage;
}

const PageImage: React.FC<IPageImageProps> = ({ image }) => {
  const imageSrc = {
    placeholder: image.formats.mobile,
    src: image.formats.desktop,
    sizes: [image.formats.mobile, image.formats.tablet, image.formats.desktop],
    webp: [image.formats['mobile_webp'], image.formats['tablet_webp'], image.formats['desktop_webp']],
  };

  return (
    <ImageInnerContainer>
      <StyledImgOuter>
        <StyledImgContainer>
          <StyledImg name="img" {...imageSrc} />
        </StyledImgContainer>
      </StyledImgOuter>
    </ImageInnerContainer>
  );
};

export default PageImage;
