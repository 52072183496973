import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { streamModalActionTypes } from 'AppState/reducers/streamModalReducer';

// Interfaces
import { IStage, ITimeslot } from 'types/interfaces';

// Local styles
import { StageColumn, StageItem, Label, StageContent } from './stage-column-container.styles';

// Theme
import theme from 'styles/theme';

// Components
import { Text } from 'components';
import { truncate } from 'lib';

interface IStageColumnContainerProps extends IWithAppState {
  list: ITimeslot[];
}

const StageColumnContainer: React.FC<IStageColumnContainerProps> = ({ list, dispatch }) => {
  const openStreamModal = (stage: IStage) => {
    dispatch({
      type: streamModalActionTypes.SET_STREAM_MODAL,
      payload: {
        isOpen: true,
        stageSlug: stage.slug,
        colors: {
          foreground: theme.colors.yellow,
          background: stage.secondaryColor,
        },
      },
    });
  };

  return (
    <StageColumn>
      {list.map((timeslot: ITimeslot, i: number) => {
        const { stage, duration, title, isLive, artists } = timeslot;
        const { slug, id } = artists[0];

        return (
          <Fragment key={i}>
            {isLive ? (
              <StageItem bgColor={stage.secondaryColor} multiplier={duration / 15} onClick={() => openStreamModal(stage)}>
                <StageContent>
                  {isLive && (
                    <Label>
                      <Text color="foreground" fontWeight="heavy" fontSize="0.8rem" lineHeight="1">
                        Live
                      </Text>
                    </Label>
                  )}
                  <Text color="white" lineHeight="1" fontSize="1.5rem" fontWeight="heavy">
                    {truncate(title, 30)}
                  </Text>
                </StageContent>
              </StageItem>
            ) : (
              <NavLink key={i} to={`/artist/${slug}/${id}?bg=${stage.secondaryColor.slice(1)}`}>
                <StageItem bgColor={stage.secondaryColor} multiplier={duration / 15}>
                  <StageContent>
                    <Text color="white" lineHeight="1" fontSize="1.5rem" fontWeight="heavy">
                      {truncate(title, 30)}
                    </Text>
                  </StageContent>
                </StageItem>
              </NavLink>
            )}
          </Fragment>
        );
      })}
    </StageColumn>
  );
};

export default withAppState(StageColumnContainer);
