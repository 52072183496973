import styled from 'styled-components';

import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

function getHoverColor(variantColor: string) {
  switch (variantColor) {
    case 'red':
      return 'yellow';
    case 'yellow':
      return 'red';
    case 'white':
      return 'red';
    default:
      return `red`;
  }
}

const styles = (p: any) => {
  return variant({
    variants: {
      default: {
        bg: p.backgroundColor || p.variantColor,
        color: p.foregroundColor || getHoverColor(p.variantColor),
        border: `1px solid`,
        borderColor: p.backgroundColor || p.variantColor,
        '&:hover': {
          color: ['', '', p.backgroundColor || p.variantColor],
          bg: ['', '', p.foregroundColor || getHoverColor(p.variantColor)],
          '& * > svg': {
            color: ['', '', p.variantColor],
          },
        },
      },
      '&:active': {
        // TODO: decide what to do with active feedback
      },

      outline: {
        color: p.foregroundColor || p.variantColor,
        bg: 'transparent',
        border: '1px solid',
        borderColor: p.foregroundColor || p.variantColor,

        '&:hover': {
          bg: ['', '', p.foregroundColor || p.variantColor],
          color: ['', '', p.backgroundColor || getHoverColor(p.variantColor)],
          '& * > svg': {
            color: ['', '', getHoverColor(p.variantColor)],
          },
        },
      },
      circle: {
        bg: p.backgroundColor || p.variantColor,
        color: p.foregroundColor || getHoverColor(p.variantColor),
        border: `1px solid`,
        borderColor: p.backgroundColor || p.variantColor,
        borderRadius: '50%',
        width: ['47px', '47px', '47px', '59px'],
        height: ['47px', '47px', '47px', '59px'],
        '& * > svg path': {
          stroke: p.foregroundColor,
        },
        '&:hover': {
          color: ['', '', p.backgroundColor || p.variantColor],
          bg: ['', '', p.foregroundColor || getHoverColor(p.variantColor)],
          '& * > svg path': {
            stroke: ['', '', p.backgroundColor],
          },
          '& * > svg stroke': {
            stroke: ['', '', p.backgroundColor],
          },
        },
      },
    },
  })(p);
};

type SystemProps = BackgroundProps & BorderProps & LayoutProps & ShadowProps & SpaceProps & TypographyProps;

export const ButtonBase = styled.button<SystemProps | any>`
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  box-sizing: border-box;
  font-family: inherit;

  font-weight: bold;
  line-height: 1;
  vertical-align: bottom;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  padding: 14px 18px;
  width: ${(p) => (p.isFullWidth ? '100%' : 'auto')};
  transition: background 0.15s, box-shadow 0.15s, border-color 0.15s, color 0.15s, opacity 0.2s;

  &:hover:enabled {
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:active {
    box-shadow: none;
  }
  &[data-is-loading] {
    cursor: wait;
  }

  ${styles}

  &&& {
    ${background}
    ${border}
    ${layout}
    ${shadow}
    ${space}
    ${typography}
  }
`;
