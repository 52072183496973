// Map Assets
import desktopCityMap from 'assets/map-city-desktop.svg';
import mobileCityMap from 'assets/map-city-mobile.svg';
import mobileBuildingMap from 'assets/map-bg-mobile.svg';
import desktopBuildingMap from 'assets/map-bg-desktop.svg';

export const mapDataBuilding = {
  imageMobile: mobileBuildingMap,
  imageDesktop: desktopBuildingMap,
  locations: [
    {
      top: ['40vh', '40vh', '60vh', '60vh'],
      left: ['10vw', '10vw', '32vw', '32vw'],
      name: 'Virtual bar',
      isLive: true,
      slug: 'virtual-bar',
      bgColor: 'E94252',
    },
    {
      top: ['12vh', '12vh', '28vh', '28vh'],
      left: ['35vw', '35vw', '43.5vw', '43.5vw'],
      name: 'Esns 01',
      isLive: false,
      slug: 'esns-01',
      bgColor: 'E94252',
    },
    {
      top: ['23vh', '23vh', '41vh', '41vh'],
      left: ['68vw', '68vw', '54vw', '54vw'],
      name: 'Esns 02',
      isLive: false,
      slug: 'esns-02',
      bgColor: '2AA693',
    },
    {
      top: ['35vh', '35vh', '52vh', '52vh'],
      left: ['62vw', '62vw', '65vw', '65vw'],
      name: 'Esns 03',
      isLive: false,
      slug: 'esns-03',
      bgColor: '8E75B4',
    },
    {
      top: ['25vh', '25vh', '42vh', '42vh'],
      left: ['18vw', '18vw', '32vw', '32vw'],
      name: 'Esns plus',
      isLive: false,
      slug: 'esns-plus',
      bgColor: 'E8A9C7',
    },
  ],
};

export const mapDataCity = {
  imageMobile: mobileCityMap,
  imageDesktop: desktopCityMap,
  locations: [
    {
      top: ['21vh', '21vh', '25vh', '25vh'],
      left: ['10vw', '10vw', '27vw', '27vw'],
      name: 'Virtual bar',
      isLive: true,
      slug: 'virtual-bar',
    },
    {
      top: ['26vh', '26vh', '28vh', '28vh'],
      left: ['67vw', '67vw', '65vw', '65vw'],
      name: 'Esns 01',
      isLive: false,
      slug: 'esns-01',
      bgColor: 'E94252',
    },
    {
      top: ['32vh', '32vh', '46vh', '46vh'],
      left: ['25vw', '25vw', '61vw', '61vw'],
      name: 'Esns 02',
      isLive: false,
      slug: 'esns-02',
      bgColor: '2AA693',
    },
    {
      top: ['40vh', '40vh', '42vh', '42vh'],
      left: ['62vw', '62vw', '40vw', '40vw'],
      name: 'Esns 03',
      isLive: false,
      slug: 'esns-03',
      bgColor: '8E75B4',
    },
    {
      top: ['42vh', '42vh', '54vh', '54vh'],
      left: ['16vw', '16vw', '28vw', '28vw'],
      name: 'Esns 04',
      isLive: false,
      slug: 'esns-04',
      bgColor: '578FE7',
    },
    {
      top: ['15vh', '15vh', '19vh', '19vh'],
      left: ['52vw', '52vw', '48vw', '48vw'],
      name: 'Esns plus',
      isLive: false,
      slug: 'esns-plus',
      bgColor: 'E8A9C7',
    },
  ],
};
