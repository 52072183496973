import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { variant } from 'styled-system';
import moment from 'moment';

// Components
import { Icon } from 'components';

// Styles
import { breakpoint } from 'styles/breakpoint';
import { useWindowSize } from 'hooks';

function getLabel(props: any) {
  switch (props.variant) {
    case 'nowLiveAtStage':
      return (
        <Label variant={props.stage?.isLive ? 'isLive' : 'hasUpcoming'}>
          {props.stage?.isLive
            ? '● LIVE NOW'
            : moment(props.stage?.upcoming.startDateTime).format('ddd, HH:mm') + ' AT ' + props.stage?.title}
        </Label>
      );
    case 'twitter':
      return <Label variant={props.variant}>TWITTER</Label>;
    case 'newsArticle':
      return <Label variant={props.variant}>NEWS</Label>;
    default:
      return null;
  }
}

export const SlideBase = (props: any) => {
  const { height } = useWindowSize();
  const theHeight = height! > 740 ? '150px' : '140px';
  return (
    <BaseStyles height={theHeight} {...props}>
      {getLabel(props)}
      {props.children}
      {props.variant !== 'announcement' && (
        <Icon icon="arrowup" position="absolute" right="15px" bottom="15px" fontSize={['22px', '22px', '32px', '32px']} />
      )}
    </BaseStyles>
  );
};

const variantStyles = (v: any) =>
  variant({
    variants: {
      nowLiveAtStage: {
        color: 'yellow',
      },
      twitter: {
        bg: '#4985E1',
        color: 'white',
      },
      newsArticle: {
        bg: 'pink',
        color: 'red',
      },
      announcement: {
        bg: 'yellow',
        color: 'red',
      },
    },
  })(v);

const labelStyles = (v: any) =>
  variant({
    variants: {
      isLive: {
        bg: 'yellow',
        color: 'red',
      },
      hasUpcoming: {
        bg: 'white',
        color: 'red',
      },
      twitter: {
        bg: 'white',
        color: '#4985E1',
      },
      newsArticle: {
        bg: 'red',
        color: 'white',
      },
    },
  })(v);

const Label = styled.span<any>`
  align-self: flex-start;
  margin: 10px 0 0 10px;
  padding: 3px 8px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  text-align: center;
  z-index: 100;

  ${breakpoint.md`
    font-weight: 800;
    font-size: 1.2rem;
    padding: 5px 10px;
  `}

  ${labelStyles}
`;

const BaseStyles = styled<any>(motion.div)`
  height: ${({ height }) => height};
  width: 240px;
  margin-left: 10px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url(${({ bgImg, types }) => (types === 'twitter' || types === 'news' || types === 'quote' ? 'unset' : bgImg)});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;

  ${variantStyles}

  ${breakpoint.md`${({ width }: { width: string }) => css`
    height: 24rem;
    min-width: 41.5rem;
    margin-left: 20px;
  `}`}
`;
