import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Components
import { Box, Flex, Text, PageTitle, Button, SEO } from 'components';

import { TitleContainer, ContentContainer } from './not-found.styles';

import { PageAnimationContainer } from 'styles/animations';

import theme from 'styles/theme';
// AppState
import withAppState, { IWithAppState } from 'AppState/withAppState';
import { colorsActionTypes } from 'AppState/reducers/colorsReducer';

const NotFound: React.FC<IWithAppState> = ({ dispatch }) => {
  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: `/404`,
    });

    dispatch({
      type: colorsActionTypes.SET_COLORS,
      payload: {
        foreground: theme.colors.red,
        background: theme.colors.pink,
      },
    });
  }, []);

  return (
    <PageAnimationContainer>
      <SEO title="Not Found | Eurosonic Noorderslag (ESNS)" />
      <Flex width="100vw" minHeight="100vh" justifyContent="flex-start" alignItems="flex-start" flexDirection="column" overflowY="hidden">
        <TitleContainer>
          <Box>
            <PageTitle title="Sorry" />
          </Box>
        </TitleContainer>
        <ContentContainer>
          <Box width={['100%', '100%', '70%']}>
            <Text
              maxWidth="1200px"
              color="red"
              fontWeight="normal"
              fontSize={{ xs: '2.4rem', sm: 2, lg: 9 }}
              lineHeight="106%"
              letterSpacing="-4%"
              mb={{ xs: '26px' }}
              display="inline-block"
            >
              404 - We can’t find the page you are looking for. Please return to the homepage.
            </Text>
          </Box>
        </ContentContainer>
        <Box pl={['20px', '20px', '70px', '70px']} pb={['40px', '80px']}>
          <NavLink to="/feed">
            <Button variant="outline" foregroundColor="red" backgroundColor="pink">
              RETURN TO HOME
            </Button>
          </NavLink>
        </Box>
      </Flex>
    </PageAnimationContainer>
  );
};

export default withAppState(NotFound);
