import styled from 'styled-components';
import { motion } from 'framer-motion';

const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const pageAnimationOptions = {
  style: {
    width: '100%',
  },
  initial: { opacity: 0, y: 40 },
  animate: {
    opacity: 1,
    y: 0,
    transition,
  },
};

export const PageAnimationContainer = styled(motion.div).attrs(() => ({
  ...pageAnimationOptions,
}))``;

const subtitleAnimationOptions = {
  style: {
    width: '100%',
  },
  initial: { opacity: 0, y: -40 },
  animate: {
    opacity: 1,
    y: 0,
    transition,
  },
};

export const SubtitleAnimationContainer = styled(motion.div).attrs(() => ({
  ...subtitleAnimationOptions,
}))`
  will-change: transform, opacity;
`;

const rightToLeftFadeAnimationOptions = {
  style: {
    width: '100%',
  },
  initial: { opacity: 0, x: 40 },
  animate: {
    opacity: 1,
    x: 0,
    transition,
  },
};

export const RightToLeftAnimationContainer = styled(motion.div).attrs(() => ({
  ...rightToLeftFadeAnimationOptions,
}))`
  will-change: transform, opacity;
`;

const leftToRightFadeAnimationOptions = {
  style: {
    width: '100%',
  },
  initial: { opacity: 0, x: -40 },
  animate: {
    opacity: 1,
    x: 0,
    transition,
  },
};

export const LeftToRightAnimationContainer = styled(motion.div).attrs(() => ({
  ...leftToRightFadeAnimationOptions,
}))`
  will-change: transform, opacity;
`;
