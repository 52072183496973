import { IStateAction } from '../AppState';

// Styles
import theme from 'styles/theme';

export interface IStreamModalReducer {
  isOpen: boolean;
  stageSlug?: string;
  artistSlug?: string;
  colors: {
    foreground?: string;
    background?: string;
  };
}
export const streamModalReducerInitialState = {
  streamModal: {
    isOpen: false,
    stageSlug: undefined,
    artistSlug: undefined,
    colors: {
      foreground: theme.colors.modalForeground,
      background: theme.colors.modalBackground,
    },
  },
};

// Exposing the reducer's action types (so we're not passing string literals around).
export const streamModalActionTypes = {
  SET_STREAM_MODAL: 'SET_STREAM_MODAL',
  CLOSE_STREAM_MODAL: 'CLOSE_STREAM_MODAL',
};

// Basic reducer to set a string literal user mode
export function streamModalReducer(state = {}, action: IStateAction): any {
  switch (action.type) {
    case streamModalActionTypes.SET_STREAM_MODAL: {
      return action.payload;
    }
    case streamModalActionTypes.CLOSE_STREAM_MODAL: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return state;
  }
}
