import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Components
import { FeedSlider, InterActiveMap, Flex, Spinner, Text, Box, Button, SEO } from 'components';

// Styled components
import { FeedWrapper, RadialGradient, Gradient } from './feed.styles';
import { PageAnimationContainer } from 'styles/animations';

import { useQuery } from 'react-query';

// FetchService
import { fetchData } from 'services/fetchService';

// Endpoints
import { apiEndpoints } from 'static/apiEndpoints';

// Interfaces
import { IExploreFeedContenType, ISettings, IStage } from 'types/interfaces';
import { useIsBreakpoint, useWindowSize } from 'hooks';

// Assets
import desktopCityMap from 'assets/map-city-desktop.svg';
import mobileCityMap from 'assets/map-city-mobile.svg';

const Feed: React.FC = () => {
  const { height } = useWindowSize();
  const { isMobile } = useIsBreakpoint();

  useEffect(() => {
    // Init page view for tracking
    (window as any).NPO?.view({
      pagina: '/feed',
    });
  }, []);

  // Get feed data
  const { data: feedData } = useQuery<IExploreFeedContenType[], string>(`explore-feed`, () => fetchData(apiEndpoints.getExploreFeed));

  // Get settings data
  const { data: settingsData } = useQuery<ISettings, string>(`settings`, () => fetchData(apiEndpoints.getSettings));

  // Get all stages data
  const { data: stagesData } = useQuery<IStage[], string>(`all-stages`, () => fetchData(apiEndpoints.getAllStages));

  if (!feedData && !settingsData) {
    return (
      <Flex minHeight="400px" alignItems="center" justifyContent="center">
        <Spinner color="foreground" width="100px" height="100px" stroke="4px" />
      </Flex>
    );
  }

  return (
    <PageAnimationContainer>
      {settingsData?.data.state === 'during' || settingsData?.data.state === 'before' || settingsData?.data.state === 'inBetween' ? (
        <FeedWrapper mobileHeight={height}>
          <SEO title="ESNS (Eurosonic Noorderslag)" />
          <InterActiveMap locations={stagesData} />
          <FeedSlider data={feedData} />
        </FeedWrapper>
      ) : (
        <FeedWrapper mobileHeight={height} bgImage={isMobile ? mobileCityMap : desktopCityMap}>
          <Gradient mobileHeight={height} />
          <RadialGradient mobileHeight={height} />
          <Flex
            position="relative"
            width={['120vw', '120vw', '1800px', '1800px']}
            alignSelf="flex-start"
            ml={['-20px', '-20px', '-80px', '-80px']}
            zIndex={10}
          >
            <Text
              fontSize={['110px', '110px', '180px', '260px']}
              color="yellow"
              fontWeight={800}
              lineHeight={['80px', '80px', '208px', '208px']}
            >
              Thank you and see you next year
              {!isMobile && (
                <Box position="absolute" right="300px" bottom="40px">
                  <NavLink to={`/programme/artists`}>
                    <Button foregroundColor="red" backgroundColor="yellow">
                      RE-WATCH ESNS 2021
                    </Button>
                  </NavLink>
                </Box>
              )}
            </Text>
          </Flex>
        </FeedWrapper>
      )}
    </PageAnimationContainer>
  );
};

export default Feed;
