import axios from 'axios';

export const fetchData = async (endpoint: string): Promise<any> => {
  const response = await axios.get(`${endpoint}`, {
    headers: {
      'site-handle': 'eurosonicNoorderslagEnglish',
      'content-type': 'application/json; charset=UTF-8',
    },
  });

  if (response) {
    return response.data;
  }

  throw response;
};
