import React from 'react';

// Components
import { Icon, Text } from 'components';

// Local Components
import { Container } from './shuffle.button.styles';

interface IShuffleButtonProps {
  onClick: () => void;
  disabled: boolean;
  hasTheme?: boolean;
}

const ShuffleButton: React.FC<IShuffleButtonProps> = ({ onClick, disabled, hasTheme = false }) => {
  return (
    <Container onClick={onClick} disabled={disabled} bgColor={hasTheme ? 'foreground' : 'purple'} isSmall={hasTheme}>
      <Icon
        icon="shuffle"
        color={hasTheme ? 'background' : 'yellow'}
        fontSize={{ xs: '18px', md: hasTheme ? '30px' : '38px' }}
        top={{ xs: '-2px', md: hasTheme ? '-3px' : '-8px' }}
        position="relative"
      />
      <Text
        color={hasTheme ? 'background' : 'yellow'}
        fontSize={{ xs: 0.5, md: hasTheme ? '21px' : '26px' }}
        fontWeight="heavy"
        lineHeight="90%"
        top={{ xs: '-2px', md: hasTheme ? '-3px' : '-8px' }}
        position="relative"
      >
        shuffle Artist
      </Text>
    </Container>
  );
};

export default ShuffleButton;
