import styled from 'styled-components';
import { motion } from 'framer-motion';

// Assets
import bgImage from 'assets/loading-screen-bg.jpg';

// Styles
import { breakpoint } from 'styles/breakpoint';

// Components
import { Text } from 'components';

export const PageWrapper = styled(motion.div)`
  background-image: url('${bgImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: black;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 5000 !important;
`;

export const HeaderWrapper = styled(motion.div)`
  display: none;

  ${breakpoint.md`
    display: block;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    
    :last-child {
      margin-right: 50px;
    }
`}
`;

export const ContentWrapper = styled(motion.div)<{ windowHeight?: number }>`
  position: relative;
  max-height: 100vh;
  height: ${({ windowHeight }) => `${windowHeight}px`};
  width: 100%;

  display: flex;
  flex-direction: column;

  ${breakpoint.md`
    height: calc(100vh - 100px);
  `}
`;

export const HeaderImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`;

export const EuroRadioLogo = styled.img`
  height: 20px;
  width: auto;
  margin: 0 10px;
`;

export const HeaderImg = styled.img`
  height: 30px;
  width: auto;
  margin: 0 10px;
`;

export const BodyImg = styled.img`
  height: 50px;
  width: auto;
  margin: 0 10px;

  ${breakpoint.md`
      height: 130px;
  `}
`;

export const LoadingText = styled(Text)`
  color: white;

  &:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`;
